/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    width: 460px;
    height: 48px;
    background: rgba(255,255,255,0.1);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid rgba(255,255,255,0.3);
    font-size: 16px;
    font-family: var(--primary-light-font);
    font-weight: 500;
    color: #ffffff;
    &:hover,&:focus,&:focus-within{
        border: 1px solid rgba(255,255,255,0.3);
        background: rgba(255,255,255,0.1) !important;
    }
    &::placeholder {
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: rgba(255, 255, 255, .7);
    }
    .ant-input {
        background: rgba(26, 26, 26,0.1);
        &::placeholder {
            font-size: 16px;
            font-family: var(--primary-light-font);
            font-weight: 500;
            color: rgba(255, 255, 255, .7);
        }
        font-size: 16px !important;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: #ffffff;
    }
    &.ant-input-disabled{
        background: #4d4d4d;
        border-color: #4d4d4d;
    }
}
.r-gray_btn,
.r-default_btn,
.r-defaultBlick_btn,
.r-defaultBlickNew_btn,
.r-primary_btn {
    position: static;
    z-index: 3;
    height: 48px;
    background: #ffffff;
    border-radius: 6px;
    opacity: 1;
    border: 1px solid #FF7957;
    font-size: 16px;
    font-family: var(--primary-semiBold-font);
    font-weight: 500;
    color: #FF7957;
    box-shadow: none;
    &:hover {
        border: 1px solid #FF7957;
        color: #FF7957;
    }
}
.r-defaultBlickNew_btn{
    width: 460px;
    cursor: pointer;
    user-select: none;
    transition: all .2s;
    background: rgba(255,255,255,0.1);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #FF7957;
    color: #FF7957;
    &:disabled,&.disabled {
        border: 1px solid rgba(255,255,255,0.3);
        color: rgba(255, 255, 255, .7);
        background-color: #161616;
        cursor: not-allowed;
    }
}
.r-cancel_btn{
    cursor: pointer;
    user-select: none;
    height: 48px;
    background: rgba(255,255,255,0.1);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid rgba(255,255,255,0.3);
    font-family: var(--primary-semiBold-font);
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, .5);
    &:hover,&:disabled,&.disabled {
        color: rgba(255, 255, 255, .5) !important;
        background: rgba(255,255,255,0.1) !important;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3) !important;
    }
}
.r-defaultBlick_btn {
    width: 460px;
    cursor: pointer;
    user-select: none;
    transition: all .2s;
    background: rgba(255,255,255,0.1);
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #FF7957;
    color: #FF7957;
    &:hover{
        border-color: #FF7957 !important;
        color: #FF7957 !important;
        background: rgba(255,255,255,0.1) !important;
    }
    &:disabled,&.disabled {
        border: 1px solid rgba(255,255,255,0.3);
        color: rgba(255, 255, 255, .7);
        background-color: #161616 !important;
        cursor: not-allowed;
    }
}
.r-primary_btn {
    border: 1px solid #FF7957;
    color: #fff;
    background-color: #FF7957;
    &:disabled {
        border: 1px solid #FF7957;
        color: #fff;
        background-color: #FF7957;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.disabled {
        border: 1px solid #FF7957;
        color: #fff;
        background-color: #FF7957;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:hover{
        color: #ffffff !important;
        background-color: #FF7957 !important;
    }
}
.r-gray_btn {
    cursor: pointer;
    user-select: none;
    transition: all .2s;
    background: #161616;
    border-radius: 6px;
    border: 1px solid #FF7957;
    color: #FF7957;
    &:hover{
        border-color: #FF7957;
        color: #FF7957;
        background: #161616 !important;
    }
    &:disabled,&.disabled {
        border: 1px solid rgba(255,255,255,0.3);
        color: rgba(255, 255, 255, .7);
        background-color: #161616;
        cursor: not-allowed;
        &:hover{
            border: 1px solid rgba(255,255,255,0.3) !important;
            color: rgba(255, 255, 255, .7) !important;
        }
    }
}
.r-select {
    height: max-content;
    .ant-select-selector {
        height: 48px !important;
        background-color: #191919 !important;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3) !important;
        .ant-select-selection-item {
            line-height: 48px;
            font-size: 16px;
            font-family: var(--primary-light-font);
            font-weight: 500;
            color: #ffffff;
        }
    }
    .ant-select-selection-placeholder {
        height: 48px !important;
        line-height: 48px !important;
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: rgba(255, 255, 255, .7);
    }
}
:where(.css-dev-only-do-not-override-1aqbfgi).ant-select-dropdown{
    font-size: 16px;
    font-family: var(--primary-light-font);
    font-weight: 500;
    color: #ffffff;
}
.ant-drawer-content-wrapper{
    width: 3.3rem !important;
}
.eye-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/eye.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.eye-slash-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/eye-slash.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.au-icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/au.png') no-repeat;
    background-size: contain;
}
.sel_arrow_icon{
    width: 24px;
    height: 24px;
    background: url('../assets/img/chevron-down.png') no-repeat;
    background-size: contain;
}
.close_icon{
    width: 20px;
    height: 20px;
    background: url('../assets/img/home/close.png') no-repeat;
    background-size: contain;
}
.loading_icon{
    display: inline-block;
    width: 42px;
    height: 42px;
    background: url('../assets/img/loading.png') no-repeat;
    background-size: contain;
}
.apple_icon{
    width: 22px;
    height: 22px;
    background: url('../assets/img/apple.png') no-repeat;
    background-size: contain;
    margin-right: 10px;
}
.google_icon{
    width: 22px;
    height: 22px;
    background: url('../assets/img/google.png') no-repeat;
    background-size: contain;
    margin-right: 10px;
}
@keyframes rotate {
    0% { transform: rotate(0deg); } 
    100% { transform: rotate(360deg); } 
}
.ant-spin .ant-spin-dot{
    animation-name: rotate; 
    animation-duration: .8s;
    animation-iteration-count: infinite; 
    animation-timing-function: linear; 
}
.r-divider{
    color: rgba(255, 255, 255, .7) !important;
    margin: 24px 0 4px 0 !important;
    &::after,&::before{
        border-block-start-color: rgba(255, 255, 255, .7) !important;
    }
}
.r-modal {
    width: 360px !important;
    .modal_tips {
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
        margin-bottom: 24px;
    }
    .ant-modal-content {
        background: rgba(51, 51, 51, 1);
        border-radius: 12px !important;
        padding: 40px 30px !important;
        box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
    }
    .r-cancel_btn{
        margin-right: 20px;
    }
    .ant-modal-close{
        display: none;
    }
    .ant-modal-header,.ant-modal-footer{
        margin: 0;
    }
}
.mb-main{
    .r-modal {
        width: 3.42rem !important;
        .modal_tips {
            font-family: Inter-Medium;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;
            line-height: .2rem;
            text-align: center;
            margin-bottom: .24rem;
        }
        .ant-modal-content {
            font-size: 16px;
            background: rgba(51, 51, 51, 1);
            border-radius: 12px !important;
            padding: .4rem .24rem !important;
            box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
        }
        .r-cancel_btn{
            margin-right: .14rem;
        }
        .r-cancel_btn,.r-primary_btn{
            height: 40px;
            font-size: .14rem;
            font-family: Inter-Medium;
        }
    }
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.emptyTetx{
    color: #FFFFFF;
    font-size: 14px;
    padding: 40px 0;
}
.home-nextBtn.disabled{
    opacity: .5;
}