.IntroductionPage-main{
    padding-top: 0;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 70px 0;
    }
    .menbership-box{
        width: 1040px;
        padding: 0 0 62px 0;
        margin: 0 auto;
        h1{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            margin-bottom: 40px;
        }
    }
}

.mb-main{
    .IntroductionPage-main{
        // padding-top: 78px;
        position: relative;
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .menbership-box{
            padding: .32rem 0 0.03rem 0;
            position: relative;
            z-index: 2;
            width: 100%;
            h1{
                width: 100%;
                text-align: center;
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: .24rem;
                color: #FFFFFF;
                line-height: .32rem;
                margin-bottom: .14rem;
            }
        }
    }
}