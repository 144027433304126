.layout-container {
    height: 100%;
    background: #000;
    position: relative;
    // height: calc(var(--app-height));
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 0 0 0 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 6px;
    }
    .header_nav {
        // position: fixed;
        // left: 0;
        // top: 0;
        z-index: 999;
    }
    .layout-bottom {
        // margin: 40px;
        // @media only screen and (max-width: 1500px) {
        //     margin: 40px;
        // }
        .ant-layout {
            background-color: #000 !important;
        }
    }
}
