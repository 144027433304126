.numInputs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    input {
        width: 60px;
        height: 72px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid rgba(255,255,255,0.3);
        text-align: center;
        margin-right: 40px;
        font-size: 24px;
        font-family: Inter-Bold;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: -2.80%;
        background: rgba(255,255,255,0.1);
        caret-color:#FF7957;
        &:focus {
            border: 1px solid #FF7957;
            box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
            outline: none;
        }
        &:last-child{
            margin: 0;
        }
    }
}
.mb-main{
    .numInputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            width: .6rem;
            height: .72rem;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid rgba(255,255,255,0.3);
            text-align: center;
            font-size: .24rem;
            font-family: Inter-Bold;
            font-weight: bold;
            color: #ffffff;
            background: rgba(255,255,255,0.1);
            caret-color:#FF7957;
            margin: 0;
            &:focus {
                border: 1px solid #FF7957;
                box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
                outline: none;
            }
        }
    }
}