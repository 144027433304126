* {
    --swiper-theme-color: #5187EF;
    --swiper-pagination-bottom: -10px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-pagination-bullet-vertical-gap: 10px;
    --swiper-pagination-bullet-border-radius: 10px;
    .swiper-pagination {
        .swiper-pagination-bullet-active {
            width: 10px;
            border: 1px solid #5187EF;
        }
    }
}
.swiper-wrapper{
    z-index: 20;
}
.subList-box {
    padding-left: .24rem !important;
    padding-right: .24rem !important;
    // .swiper-wrapper {
    //     align-items: stretch;
    //     margin-right: .16rem !important;
    // }
    .swiper-slide {
        width: 3.35rem;
        // margin-right: .16rem !important;
    }
}
.memberOption-box{
    padding-left: .24rem !important;
    padding-right: .24rem !important;
    .swiper-slide {
        width: 3.08rem;
        // margin-right: .16rem !important;
    }
}
.swiper-button-prev:after {
    display: none;
}
.swiper-button-next:after {
    display: none;
}
.swiper-pagination-bullet {
    border: 1px solid #fff;
}
