.home-main{
    padding: 0 0 106px 0;
    width: 1040px;
    margin: 0 auto;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 70px 0;
    }
    .profile-numBox{
        .statistics-card{
            flex: 1;
            padding: 20px;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 24px;
            text-align: left;
            background: rgba(255,255,255,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid rgba(255,255,255,0.2);
            letter-spacing: -0.2px;
            &.gift-card{
                background: #5187EF;
                box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.04);
                border-radius: 8px 8px 8px 8px;
                border-color: #5187EF;
                color: #FFFFFF;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 94px;
                    height: 100%;
                    background: url('../../../../assets/img/gift.png') no-repeat;
                    background-size: contain;
                }
            }
            &:nth-child(2){
                margin:0 28px;
            }
            .sta-num{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 24px;
                color: #5187EF;
                line-height: 32px;
                margin-top: 10px;
                &.gift-num{
                    color: #FFFFFF;
                }
            }
        }
    }
    .prizes-tit{
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 24px;
        .r-defaultBlick_btn{
            width: 220px;
        }
    }
    .sub-card{
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        &:nth-child(2){
            margin: 0 17px;
        }
        .subCard_top{
            padding: 20px;
            position: relative;
            background: rgba(255,255,255,0.05);
            .sub-icon{
                width: 72px;
                height: 72px;
                margin-right: 12px;
            }
            .sub-tit{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
            }
            .sub-price{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 24px;
                color: #5187EF;
                margin-top: 14px;
            }
            .maxIcBox{
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                margin-left: 6px;
                opacity: .7;
            }
            .mostBox{
                position: absolute;
                top: 0;
                right: 0;
                width: 116px;
                height: 32px;
                background: #FF7957;
                border-radius: 0px 8px 0px 8px;
                font-size: 12px;
                font-weight: 500;
                font-family: Inter-Medium;
                color: #FFFFFF;
                text-align: center;
                line-height: 32px;
            }
        }
        .sub_content{
            padding: 20px;
            .subInfo_box{
                margin-bottom: 16px;
                align-items: flex-start;
            }
            .check_icon{
                width: 16px;
                min-width: 16px;
                height: 16px;
                margin:2px 8px 0 0;
                background: url('../../../../assets/img/home/checked.png') no-repeat;
                background-size: contain;
            }
            .sub_tip{
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                opacity: .7;
            }
            .sub_tipBtm{
                width: max-content;
                padding: 8px 90px 8px 8px;
                background: #70402f;
                border-radius: 6px 6px 6px 6px;
                margin: 0;
                .tip_icon{
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin:2px 8px 0 0;
                    background: url('../../../../assets/img/home/tip.png') no-repeat;
                    background-size: contain;
                }
            }
            .homeSign-btn{
                width: 406px;
                margin-top: 16px;
            }
        }
    }
    .r-primary_btn{
        width: 460px;
        margin: 50px 0 80px 0;
    }
}

.mb-main{
    .home-main{
        padding: 0 .24rem .32rem .24rem;
        position: relative;
        z-index: 3;
        width: 100%;
        &::after{
            content: '';
            position: fixed;
            width: 114px;
            height: 130px;
            top: 0;
            right: 0;
            background: url('../../../../assets/img/nav-top-bg.png') no-repeat;
            z-index: 1;
            background-size: cover;
        }
        .logo-box{
            display: none;
        }
        .profile-numBox{
            position: relative;
            z-index: 3;
            display: block;
            margin-top: 8px;
            .statistics-card{
                width: 100%;
                padding: 20px;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: .2rem;
                text-align: left;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid rgba(255,255,255,0.2);
                letter-spacing: -0.2px;
                text-align: center;
                &.gift-card{
                    background: #5187EF;
                    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.04);
                    border-radius: 8px 8px 8px 8px;
                    border-color: #5187EF;
                    color: #FFFFFF;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        // width: 94px;
                        height: 100%;
                        background: url('../../../../assets/img/gift.png') no-repeat;
                        background-size: cover;
                        border-radius: 0 8px 9px 0;
                    }
                }
                &:nth-child(2){
                    margin:.16rem 0;
                }
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #5187EF;
                    line-height: .32rem;
                    margin-top: 6px;
                    &.gift-num{
                        color: #FFFFFF;
                    }
                }
            }
        }
        .r-primary_btn{
            width: 100%;
            margin: .24rem 0 .34rem 0;
            height: 40px;
            font-size: .14rem;
        }
        .prizes-tit{
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            font-size: .14rem;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: .18rem;
            position: static;
            z-index: 3;
            .r-defaultBlick_btn{
                width: 1.29rem;
                height: 36px;
                font-size: .14rem;
                margin-left: .16rem;
            }
        }
        .sub-card{
            width: 100%;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            .subCard_top{
                padding: .2rem .2rem .1rem .2rem;
                position: relative;
                background: rgba(255,255,255,0.05);
                align-items: flex-start;
                .sub-icon{
                    width: .72rem;
                    height: .72rem;
                    margin-right: .12rem;
                }
                .sub-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: .2rem;
                    color: #FFFFFF;
                    line-height: .24rem;
                }
                .sub-price{
                    height: 48px;
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: .23rem;
                    color: #5187EF;
                    margin-top: 5px;
                }
                .maxIcBox{
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    margin-left: 6px;
                    opacity: .7;
                }
                .mostBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1.16rem;
                    height: .32rem;
                    background: #FF7957;
                    border-radius: 0px 8px 0px 8px;
                    font-size: .12rem;
                    font-weight: 500;
                    font-family: Inter-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: .32rem;
                }
            }
            .sub_content{
                padding: .2rem;
                .subInfo_box{
                    margin-bottom: .16rem;
                    align-items: flex-start;
                }
                .check_icon{
                    width: .16rem;
                    min-width: .16rem;
                    height: .16rem;
                    margin:.01rem .08rem 0 0;
                    background: url('../../../../assets/img/home/checked.png') no-repeat;
                    background-size: contain;
                }
                .sub_tip{
                    font-family: Inter-Regular;
                    font-weight: 400;
                    font-size: .12rem;
                    color: #FFFFFF;
                    line-height: .16rem;
                    opacity: .7;
                }
                .sub_tipBtm{
                    padding: .08rem;
                    width: 100%;
                    background: #70402f;
                    border-radius: 6px 6px 6px 6px;
                    margin: 0;
                    .tip_icon{
                        width: .16rem;
                        min-width: .16rem;
                        height: .16rem;
                        margin:.02rem .08rem 0 0;
                        background: url('../../../../assets/img/home/tip.png') no-repeat;
                        background-size: contain;
                    }
                } 
                .homeSign-btn{
                    margin-top: .16rem;
                    width: 100%;
                    height: 40px;
                    font-size: .14rem;
                }
            }
        }
    }
}