.Profile-main{
    padding: 200px 0 106px 0;
    width: 100%;
    align-items: flex-start !important;
    .logo-box{
        width: 460px;
        height: 340px;
        margin-right: 120px;
    }
    .profile-numBox{
        .statistics-card{
            width: 460px;
            padding: 20px;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 24px;
            text-align: left;
            background: #5187EF;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #5187EF;
            letter-spacing: -0.2px;
            text-align: center;
            .sta-num{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                margin-top: 10px;
            }
            .mar10{
                margin: 10px 0;
            }
        }
    }
    .mem-tit{
        margin: 40px 0 24px 0;
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 40px;
    }
    .sub-card{
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        .subCard_top{
            padding: 20px;
            position: relative;
            background: rgba(255,255,255,0.05);
            .sub-icon{
                width: 72px;
                height: 72px;
                margin-right: 12px;
            }
            .sub-tit{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
            }
            .sub-date{
                font-family: PlusJakartaSans-Bold;
                font-weight: bold;
                font-size: 14px;
                color: #5187EF;
                line-height: 16px;
                margin: 10px 0 4px 0;
            }
            .sub-expird{
                font-family: Inter;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                opacity: .7;
            }
            .price-box{
                align-items: flex-start;
                color: #5187EF;
                margin: 4px 0 8px 0;
                position: absolute;
                right: 20px;
                top: 20px;
                .priceLef{
                    font-size: 12px;
                    font-family: Inter-Medium;
                    margin: 2px 3px 0 0;
                }
                .priceNum{
                    font-size: 20px;
                    font-family: Inter-Bold;
                }
            }
        }
    }
    .profileItem_list{
        width: 460px;
        height: 259px;
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        margin-top: 40px;
        padding: 2px 20px 20px 20px;
        .opeation-item{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 24px;
            padding: 18px 0;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            cursor: pointer;
            user-select: none;
            .chevron-right_icon{
                width: 24px;
                height: 24px;
                background: url('../../../assets/img/chevron-right.png') no-repeat;
                background-size: contain;
            }
        }
    }
}
// edit profile
.editProfile-main{
    padding: 200px 0 106px 0;
    width: 100%;
    align-items: flex-start !important;
    .logo-box{
        width: 460px;
        height: 340px;
        margin-right: 120px;
    }
    .profile-form{
        h1{
            font-family: Inter-Bold;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            padding-bottom: 4px;
        }
        .form-tip{
            font-family: Inter-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 24px;
            margin: 20px 0 8px 0;
        }
        .ageSel-input{
            position: relative;
            .r-age-date{
                position: absolute;
                width: 100%;
                height: 48px;
                left: 0;
                opacity: 0;
                // z-index: -10;
            }
            // .r-input:hover{
            //     .close_icon{
            //         display: block;
            //     }
            // }
        }
        .profileform-footer{
            margin-top: 40px;
            justify-content: space-between;
            .cancel-btn,.r-primary_btn{
                width: 220px;
            }
        }
    }
}
// membership
.membership-main{
    width: 1040px;
    margin: 0 auto;
    padding: 0 0 22px 0;
    align-items: flex-start !important;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 30px 0;
    }
    .sub-card{
        width: 460px;
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        .subCard_top{
            cursor: pointer;
            padding: 20px;
            position: relative;
            background: rgba(255,255,255,0.05);
            .sub-icon{
                width: 72px;
                height: 72px;
                margin-right: 12px;
            }
            .sub-tit{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
            }
            .sub-date{
                font-family: PlusJakartaSans-Bold;
                font-weight: bold;
                font-size: 14px;
                color: #5187EF;
                line-height: 16px;
                margin: 10px 0 4px 0;
            }
            .sub-expird{
                font-family: Inter;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                opacity: .7;
            }
            .price-box{
                align-items: flex-start;
                color: #5187EF;
                margin: 4px 0 8px 0;
                position: absolute;
                right: 20px;
                top: 20px;
                .priceLef{
                    font-size: 12px;
                    font-family: Inter-Medium;
                    margin: 2px 3px 0 0;
                }
                .priceNum{
                    font-size: 20px;
                    font-family: Inter-Bold;
                }
            }
        }
    }
    h2{
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: left;
        margin: 40px 0;
    }
    .mar24{
        margin: 40px 0 24px 0;
    }
}
// Points record
.pointrecord-main{
    width: 1040px;
    margin: 0 auto;
    .pointH1{
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 40px;
        margin-top: 50px;
    }
    .record-main{
        .month-box{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;
            margin: 40px 0 20px 0;
        }
        .record-list{
            flex-wrap: wrap;
        }
        .record-card{
            padding: 20px;
            width: 328px;
            min-width: 328px;
            height: 94px;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            margin-bottom: 20px;
            margin-right: 28px;
            &:nth-child(3n){
                margin-right:0 ;
            }
            .quesInfo{
                font-family: Inter-SemiBold, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: left;
            }
            .get-time{
                font-family: Inter, Inter;
                font-weight: 400;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.7);
                line-height: 20px;
                margin-top: 10px;
            }
        }
        .prize-card,.draw-card{
            height: auto;
            .pointNum{
                font-size: 16px;
            }
            .prizeGet_time{
                margin-top: 4px;
            }
            .drawpointNum{
                opacity: 1;
                color: #FFFFFF;
                margin: 10px 0 4px 0;
            }
            .prizeStatus{
                font-family: Inter-Bold;
                font-size: 14px;
            }
        }
    }
    .prize-main_new{
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 40px;
    }
    .draw-box{
        flex-wrap: wrap;
    }
}
.zIndexTop{
    position: relative;
    z-index: 2;
}
.mb-main{
    .Profile-main{
        padding: 0 .24rem .32rem .24rem;
        position: relative;
        width: 100%;
        z-index: 2;
        &::before{
            content: '';
            position: fixed;
            width: 114px;
            height: 130px;
            top: 0;
            right: 0;
            background: url('../../../assets/img/nav-top-bg.png') no-repeat;
            z-index: 1;
            background-size: cover;
        }
        &::after{
            content: '';
            position: fixed;
            top: 400px;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .profile-rightBox{
            width: 100%;
            position: relative;
            z-index: 2;
        }
        .profile-numBox{
            margin-top: .08rem;
            .statistics-card{
                width: 100%;
                padding: .2rem;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: .2rem;
                text-align: left;
                background: #5187EF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #5187EF;
                letter-spacing: -0.2px;
                text-align: center;
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                }
                .mar10{
                    margin: .1rem 0;
                }
            }
        }
        .mem-tit{
            margin: .2rem 0 .1rem 0;
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: .16rem;
            color: #FFFFFF;
            line-height: .24rem;
        }
        .sub-card{
            background: rgba(255,255,255,0.1);
            background: #191919;
            border-radius: 8px 8px 8px 8px;
            // border: 1px solid rgba(255,255,255,0.3);
            .subCard_top{
                padding: .2rem;
                position: relative;
                .sub-icon{
                    width: .72rem;
                    height: .72rem;
                    margin-right: .12rem;
                }
                .sub-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                }
                .sub-date{
                    font-family: PlusJakartaSans-Bold;
                    font-weight: bold;
                    font-size: .14rem;
                    color: #5187EF;
                    line-height: .16rem;
                    margin: .07rem 0 .04rem 0;
                }
                .sub-expird{
                    font-family: Inter;
                    font-weight: 400;
                    font-size: .12rem;
                    color: #FFFFFF;
                    line-height: .16rem;
                    opacity: .7;
                }
                .price-box{
                    align-items: flex-start;
                    color: #5187EF;
                    position: absolute;
                    right: .2rem;
                    top: .2rem;
                    .priceLef{
                        font-size: .12rem;
                        font-family: Inter-Medium;
                        margin: 2px 3px 0 0;
                    }
                    .priceNum{
                        font-size: .2rem;
                        font-family: Inter-Bold;
                    }
                }
            }
        }
        .profileItem_list{
            width: 100%;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 8px 8px 8px 8px;
            border: 0px;
            margin-top: .2rem;
            padding: 0;
            .opeation-item{
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                padding: 18px 0;
                border-bottom: 1px solid rgba(255,255,255,0.3);
                cursor: pointer;
                user-select: none;
                .chevron-right_icon{
                    width: 24px;
                    height: 24px;
                    background: url('../../../assets/img/chevron-right.png') no-repeat;
                    background-size: contain;
                }
            }
        }
    }
    .editProfile-main{
        padding: 0 .24rem .32rem .24rem;
        position: relative;
        width: 100%;
        z-index: 2;
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .profile-form{
            margin-top: .32rem;
            width: 100%;
            position: relative;
            z-index: 2;
            h1{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: .24rem;
                color: #FFFFFF;
                line-height: .32rem;
                padding-bottom: .06rem;
                text-align: center;
            }
            .form-tip{
                font-family: Inter-Medium;
                font-weight: 500;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                margin: .16rem 0 .08rem 0;
            }
            .ageSel-input{
                position: relative;
                .r-age-date{
                    position: absolute;
                    width: 100%;
                    height: 48px;
                    left: 0;
                    opacity: 0;
                    // z-index: -10;
                }
                // .r-input:hover{
                //     .close_icon{
                //         display: block;
                //     }
                // }
            }
            .r-input{
                width: 100%;
            }
            .profileform-footer{
                margin-top: .24rem;
                justify-content: space-between;
                .cancel-btn,.r-primary_btn{
                    width: 1.63rem;
                    font-size: .14rem;
                    font-family: Inter-Medium;
                    height: .4rem;
                    font-weight: normal;
                }
            }
        }
    }
    .membership-main{
        width: 100%;
        margin: 0 auto;
        padding: 0 0 22px 0;
        align-items: flex-start !important;
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .menbership-box{
            position: relative;
            z-index: 3;
        }
        .logo-box{
            width: 158px;
            height: 124px;
            margin: 20px 0 30px 0;
            display: none;
        }
        .pad24{
            padding: 0 .24rem .1rem .24rem;
        }
        .sub-card{
            width: 100%;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            .subCard_top{
                padding: 20px;
                position: relative;
                background: rgba(255,255,255,0.05);
                .sub-icon{
                    width: 72px;
                    height: 72px;
                    margin-right: 12px;
                }
                .sub-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 32px;
                }
                .sub-date{
                    font-family: PlusJakartaSans-Bold;
                    font-weight: bold;
                    font-size: 14px;
                    color: #5187EF;
                    line-height: 16px;
                    margin: 10px 0 4px 0;
                }
                .sub-expird{
                    font-family: Inter;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    opacity: .7;
                }
                .price-box{
                    align-items: flex-start;
                    color: #5187EF;
                    margin: 4px 0 8px 0;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    .priceLef{
                        font-size: 12px;
                        font-family: Inter-Medium;
                        margin: 2px 3px 0 0;
                    }
                    .priceNum{
                        font-size: 20px;
                        font-family: Inter-Bold;
                    }
                }
            }
        }
        h2{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: .20rem;
            color: #FFFFFF;
            line-height: .24rem;
            text-align: left;
            margin: .34rem 0 .16rem 0;
            padding: 0 .24rem;
        }
        .mb-h2{
            font-size: .24rem;
            text-align: center;
        }
        .mar24{
            margin: .32rem 0 .22rem 0;
            text-align: center;
        }
    }
    .pointrecord-main{
        width: 100%;
        margin: 0 auto;
        padding: 0 .24rem .24rem .24rem;
        position: relative;
        z-index: 2;
        &::before{
            display: none;
        }
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .pointH1{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: .24rem;
            color: #FFFFFF;
            line-height: .32rem;
            margin: .32rem 0 0 0;
            text-align: center;
        }
        .record-main{
            .month-box{
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .16rem;
                color: #FFFFFF;
                line-height: .24rem;
                margin: .3rem 0 .12rem 0;
            }
            .record-list{
                display: block;
            }
            .record-card{
                padding: .12rem 0;
                width: 100%;
                min-width: 100%;
                height: .64rem;
                background: rgba(255,255,255,0);
                border: none;
                border-radius: 0;
                border-bottom: 1px solid rgba(255,255,255,0.3);
                margin: 0;
                &:first-child{
                    border-top: 1px solid rgba(255,255,255,0.3);
                }
                .quesInfo{
                    font-family: Inter-SemiBold, Inter;
                    font-weight: normal;
                    font-size: .14rem;
                    color: #FFFFFF;
                    line-height: .2rem;
                    text-align: left;
                }
                .get-time{
                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: .12rem;
                    color: rgba(255, 255, 255, 0.7);
                    line-height: .16rem;
                    margin-top: 4px;
                }
            }
            .draw-card{
                height: auto;
                .drawpointNum{
                    opacity: 1;
                    color: #FFFFFF;
                    margin: .05rem 0;
                }
            }
        }
        .prize-main_new{
            display: block;
            margin-top: .18rem;
            .prize-card{
                width: 100%;
                height: auto;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                padding: .2rem;
                margin-bottom: .12rem;
            }
        }
    }
}
.disabledInput{
    color: #CACACA !important;
}