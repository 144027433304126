.home-page{
    overflow: hidden;
    .nav_top{
        // position: fixed;
        top: 0;
        width: 100%;
        height: 64px;
        background: #191919;
        border-radius: 0px 0px 0px 0px;
        padding: 0 10%;
        z-index: 10;
        .logo-box{
            width: 62px;
            height: 48px;
        }
        .top-tip{
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 23px;
            font-style: normal;
            margin-left: auto;
        }
        .r-primary_btn{
            width: 160px;
            margin-left: 50px;
        }
    }
    .content-box{
        padding: 0 200px 57px 200px;
        max-width: 1440px;
        width: 1440px;
        margin: 0 auto;
        .logo-large{
            width: 158px;
            height: 124px;
            margin: 20px 0 12px 0;
        }
        .content-tit{
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 38px;
            text-align: center;
        }
        .content-col{
            color: #5187EF;
            font-family: Inter-Regular;
            text-align: center;
            font-style: italic;
            margin-left: 5px;
        }
        .top-tip{
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 23px;
            text-align: center;
            margin-top: 8px;
            opacity: .7;
        }
        .card-box{
            margin: 0 auto 0 auto;
            justify-content: space-between;
            &.mar80{
                margin-top:70px;
                margin-bottom: 80px;
            }
            .card-img{
                height: 320px;
                width: 480px;
                min-width: 480px;
            }
            .rowbox{
                width: 100px;
                height: 100%;
                min-width: 100px;
            }
            .card-rightBox{
                .cardmsg-box{
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 24px;
                    align-items: flex-start;
                    letter-spacing: -0.1px;
                    .card-icon{
                        margin:0 16px 6px 0;
                        width: 44px;
                        min-width: 44px;
                        height: auto;
                        // background-color: #5187EF;
                    }
                    .work_first{
                        margin-top: 12px;
                    }
                }
                .sign-btn{
                    margin-top: 40px;
                }
                .category-listBox{
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .category-box{
                        padding: 12px;
                        margin-top: 20px;
                        width: 49%;
                        height: 48px;
                        background: rgba(255,255,255,0.1);
                        border-radius: 8px 8px 8px 8px;
                        border: 1px solid rgba(255,255,255,0.3);
                        .category-icon{
                            width: 24px;
                            height: 24px;
                            margin-right: 4px;
                        }
                    }
                }
                .marB20{
                    margin-bottom: 20px;
                }
            }
        }
        .max-img{
            width: 100%;
            height: auto;
        }
        .subList-box{
            justify-content: space-between;
            align-items: flex-start;
            .sub-card{
                flex: 1;
                height: 610px;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                &:nth-child(2){
                    margin: 0 17px;
                }
                .subCard_top{
                    padding: 20px 20px 10px 20px;
                    position: relative;
                    background: rgba(255,255,255,0.05);
                    align-items: flex-start;
                    .sub-icon{
                        width: 72px;
                        height: 72px;
                        margin-right: 12px;
                    }
                    .sub-tit{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: 24px;
                        color: #FFFFFF;
                        line-height: 29px;
                    }
                    .sub-price{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: 24px;
                        color: #5187EF;
                        margin-top: 5px;
                    }
                    .maxIcBox{
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 20px;
                        margin-left: 6px;
                        opacity: .7;
                    }
                    .mostBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 116px;
                        height: 32px;
                        background: #FF7957;
                        border-radius: 0px 8px 0px 8px;
                        font-size: 12px;
                        font-weight: 500;
                        font-family: Inter-Medium;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 32px;
                    }
                }
                .sub_content{
                    padding: 20px;
                    .sub-listBoxnew{
                        max-height: 384px;
                        overflow-y: auto;
                        margin-bottom: 16px;
                    }
                    .subInfo_box{
                        margin-bottom: 16px;
                        align-items: flex-start;
                        &:last-child{
                            margin: 0;
                        }
                    }
                    .check_icon{
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin:2px 8px 0 0;
                        background: url('../../../assets/img/home/checked.png') no-repeat;
                        background-size: contain;
                    }
                    .sub_tip{
                        font-family: Inter-Regular;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 20px;
                        opacity: .7;
                        letter-spacing: -0.1px;
                    }
                    .sub_tipBtm{
                        padding: 8px;
                        width: 100%;
                        background: #70402f;
                        border-radius: 6px 6px 6px 6px;
                        .tip_icon{
                            width: 16px;
                            min-width: 16px;
                            height: 16px;
                            margin:2px 8px 0 0;
                            background: url('../../../assets/img/home/tip.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
        .memberOption-box{
            margin-bottom: 80px;
            justify-content: space-between;
            .member-card{
                max-width: 358px;
                padding: 20px;
                flex: 1;
                min-height: 570px;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                position: relative;
                &:nth-child(2){
                    background: rgba(81, 135, 239, 0.30);
                    margin: 0 28px;
                }
                .mem-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 32px;
                    margin-bottom: 16px;
                }
                .mostBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 116px;
                    height: 32px;
                    background: #FF7957;
                    border-radius: 0px 8px 0px 8px;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: Inter-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 32px;
                }
                .price-card{
                    width: 100%;
                    min-height: 118px;
                    background: rgba(255,255,255,0.05);
                    border-radius: 6px 6px 6px 6px;
                    border: 1px solid rgba(255,255,255,0.2);
                    padding: 12px;
                    position: relative;
                    &.mar12{
                        margin: 12px 0;
                    }
                    .price-date{
                        font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                        font-weight: bold;
                        font-size: 14px;
                        color: #5187EF;
                        line-height: 16px;
                    }
                    .price-box{
                        align-items: flex-start;
                        color: #FFFFFF;
                        margin: 4px 0 8px 0;
                        .priceLef{
                            font-size: 14px;
                            font-family: Inter-Medium;
                            margin: 2px 3px 0 0;
                        }
                        .priceNum{
                            font-size: 24px;
                            font-family: Inter-Bold;
                        }
                    }
                    .price-pts{
                        margin-left: auto;
                        font-family: Inter-SemiBold;
                        font-weight: normal;
                        font-size: 16px;
                        color: #FF7957;
                        line-height: 24px;
                    }
                    .price-tip{
                        font-family: Inter;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        opacity: .7;
                        line-height: 20px;
                    }
                    .discountBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 119px;
                        height: 24px;
                        background: #5187EF;
                        border-radius: 0px 6px 0px 8px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: Inter-Regular;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 24px;
                    }
                }
            }
            .sign-btn{
                width: 288px;
                margin-top: 16px;
            }
        }
        h3{
            font-family: Inter-Bold;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            margin-bottom: 40px;
        }
    }
    .sign-btn{
        width: 342px;
        height: 48px;
        background: rgba(255,121,87,0.05);
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #FF7957;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        color: #FF7957;
    }
    .mar80{
        margin: 80px 0;
    }
}
.mb-main{
    .home-page{
        background-color: #000000;
        position: relative;
        z-index: 2;
        &::before{
            content: '';
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            background: url('../../../assets/img/home/top-right.png') no-repeat;
            background-size: contain;
            width: 1.3rem;
            height: 2.83rem;
        }
        &::after{
            content: '';
            position: absolute;
            z-index: 1;
            top: 4.82rem;
            right: 0;
            bottom: 0;
            background: url('../../../assets/img/home/max-bg.png') no-repeat;
            background-size: contain;
            width: 100%;
            // height: 1000px;
        }
        .nav_top{
            width: 100%;
            height: 64px;
            background: rgba(255, 121, 87, 0);
            border-radius: 0px 0px 0px 0px;
            padding: 0 .24rem;
            box-shadow: none;
            .r-primary_btn,.top-tip,.logo-box{
                display: none;
            }
            .menu-icon{
                width: 31px;
                height: 31px;
                background: url('../../../assets/img/home/menu.png') no-repeat;
                background-size: contain;
            }
            .top_btn{
                width: 89px;
                height: 40px;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #FFFFFF;
                font-family: Inter-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                margin-left: auto;
            }
        }
        .content-box{
            position: relative;
            z-index: 3;
            padding: 0 .0 .48rem 0;
            max-width: 750px;
            width: 100%;
            margin: 0 auto;
            .logo-large{
                width: 1.58rem;
                height: 1.24rem;
                margin: .12rem 0;
            }
            .content-tit{
                padding: 0 .24rem;
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: .20rem;
                color: #FFFFFF;
                line-height: .24rem;
                text-align: center;
            }
            .content-col{
                color: #5187EF;
                font-family: Inter-Regular;
                text-align: center;
                font-style: italic;
                margin-left: 5px;
            }
            .top-tip{
                padding: 0 .24rem;
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                text-align: center;
                margin-top: 0.08rem;
                opacity: .7;
            }
            .card-box{
                padding: 0 .24rem;
                flex-direction: column;
                margin: 0 auto 0 auto;
                justify-content: space-between;
                &.mar80{
                    margin-top: .48rem;
                    margin-bottom: .48rem;
                }
                .card-img{
                    height: 2.25rem;
                    width: 3.42rem;
                    min-width: 100%;
                }
                .rowbox{
                    width: 0;
                    height: 0;
                }
                .card-rightBox{
                    h3{
                        margin-top: .48rem;
                    }
                    .cardmsg-box{
                        font-weight: 400;
                        font-size: .14rem;
                        color: #FFFFFF;
                        line-height: .20rem;
                        align-items: flex-start;
                        .card-icon{
                            margin:0 .16rem .06rem 0;
                            width: .44rem;
                            min-width: .44rem;
                            height: auto;
                            // background-color: #5187EF;
                        }
                        .work_first{
                            margin-top: .12rem;
                        }
                    }
                    .sign-btn{
                        margin-top: .26rem;
                    }
                    .category-listBox{
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .category-box{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            min-height: .48rem;
                            background: rgba(255,255,255,0.1);
                            border-radius: 8px 8px 8px 8px;
                            border: 1px solid rgba(255,255,255,0.3);
                            margin:0 0 .12rem 0;
                            width: 1.65rem;
                            padding: .12rem;
                            font-size: .12rem;
                            letter-spacing: -.3px;
                            .category-icon{
                                width: .24rem;
                                min-width: .24rem;
                                height: .24rem;
                                margin-right: 4px;
                            }
                        }
                    }
                    .marB20{
                        margin-bottom: .2rem;
                    }
                }
            }
            .revice{
                flex-direction: column-reverse;
            }
            .max-bg{
                width: 100%;
                height: 2.25rem;
                border-radius: 8px;
                padding: 0 .24rem;
                vertical-align: middle;
                margin: .36rem 0 .48rem 0;
            }
            .max-mb{
                border-radius: 8px;
                width: 3.42rem;
                height: 3.46rem;
                object-fit: cover;
                padding: 0;
                vertical-align: middle;
                margin: .3rem .24rem .48rem .24rem;
            }
            .max-mb3{
                padding: 0;
                border-radius: 8px;
                width: 3.42rem;
                height: 1.64rem;
                object-fit: cover;
                margin: 0 .24rem;
                vertical-align: middle;
            }
            .subList-box{
                justify-content: space-between;
                align-items: stretch;
                padding-bottom: .5rem;
                .sub-card{
                    flex: 1;
                    min-height: auto;
                    // height: 5.45rem;
                    height: 5.03rem;
                    background: rgba(255,255,255,0.1);
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid rgba(255,255,255,0.3);
                    .subCard_top{
                        padding: .2rem .2rem .1rem .2rem;
                        position: relative;
                        background: rgba(255,255,255,0.05);
                        align-items: flex-start;
                        .sub-icon{
                            width: .72rem;
                            height: .72rem;
                            margin-right: .12rem;
                        }
                        .sub-tit{
                            font-family: Inter-Bold;
                            font-weight: bold;
                            font-size: .2rem;
                            color: #FFFFFF;
                            line-height: .24rem;
                        }
                        .sub-price{
                            height: 48px;
                            font-family: Inter-Bold;
                            font-weight: bold;
                            font-size: .23rem;
                            color: #5187EF;
                            margin-top: 5px;
                        }
                        .maxIcBox{
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 20px;
                            margin-left: 6px;
                            opacity: .7;
                        }
                        .mostBox{
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 1.16rem;
                            height: .32rem;
                            background: #FF7957;
                            border-radius: 0px 8px 0px 8px;
                            font-size: .12rem;
                            font-weight: 500;
                            font-family: Inter-Medium;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: .32rem;
                        }
                    }
                    .sub_content{
                        padding: .2rem;
                        .sub-listBoxnew{
                            max-height: 2.95rem;
                            overflow-y: auto;
                            margin-bottom: .16rem;
                        }
                        .subInfo_box{
                            margin-bottom: .16rem;
                            align-items: flex-start;
                            &:last-child{
                                margin: 0;
                            }
                        }
                        .check_icon{
                            width: .16rem;
                            min-width: .16rem;
                            height: .16rem;
                            margin:.01rem .08rem 0 0;
                            background: url('../../../assets/img/home/checked.png') no-repeat;
                            background-size: contain;
                        }
                        .sub_tip{
                            font-family: Inter-Regular;
                            font-weight: 400;
                            font-size: .12rem;
                            color: #FFFFFF;
                            line-height: .16rem;
                            opacity: .7;
                        }
                        .sub_tipBtm{
                            padding: .08rem;
                            width: 100%;
                            background: #70402f;
                            border-radius: 6px 6px 6px 6px;
                            .tip_icon{
                                width: .16rem;
                                min-width: .16rem;
                                height: .16rem;
                                margin:.02rem .08rem 0 0;
                                background: url('../../../assets/img/home/tip.png') no-repeat;
                                background-size: contain;
                            }
                        }
                    }
                }
            }
            .mar80{
                margin: 0;
            }
            .memberOption-box{
                margin-bottom: .30rem;
                justify-content: space-between;
                padding-bottom: .5rem;
                .member-card{
                    width: 3.08rem;
                    max-width: 3.08rem;
                    padding: .2rem;
                    min-height: auto;
                    background: rgba(255,255,255,0.1);
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid rgba(255,255,255,0.3);
                    position: relative;
                    &.other-color{
                        background: rgba(81, 135, 239, 0.30);
                    }
                    .mem-tit{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: .2rem;
                        color: #FFFFFF;
                        line-height: .24rem;
                        margin-bottom: .16rem;
                    }
                    .mostBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1.19rem;
                        height: .32rem;
                        background: #FF7957;
                        border-radius: 0px 8px 0px 8px;
                        font-size: .12rem;
                        font-weight: 500;
                        font-family: Inter-Medium;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: .32rem;
                    }
                    .price-card{
                        width: 100%;
                        // min-height: 1.1rem;
                        background: rgba(255,255,255,0.05);
                        border-radius: 6px 6px 6px 6px;
                        border: 1px solid rgba(255,255,255,0.2);
                        padding: .12rem;
                        position: relative;
                        &.mar12{
                            margin: .12rem 0;
                        }
                        .price-date{
                            font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                            font-weight: bold;
                            font-size: .14rem;
                            color: #5187EF;
                            line-height: .16rem;
                        }
                        .price-box{
                            align-items: flex-start;
                            color: #FFFFFF;
                            margin: 4px 0 8px 0;
                            .priceLef{
                                font-size: .13rem;
                                font-family: Inter-Medium;
                                margin: 2px 3px 0 0;
                            }
                            .priceNum{
                                font-size: .2rem;
                                font-family: Inter-Bold;
                            }
                        }
                        .price-pts{
                            margin-left: auto;
                            font-family: Inter-SemiBold;
                            font-weight: normal;
                            font-size: .14rem;
                            color: #FF7957;
                            line-height: .2rem;
                        }
                        .price-tip{
                            font-family: Inter;
                            font-weight: 400;
                            font-size: .12rem;
                            color: #FFFFFF;
                            opacity: .7;
                            line-height: .2rem;
                        }
                        .discountBox{
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 119px;
                            height: 24px;
                            background: #5187EF;
                            border-radius: 0px 6px 0px 8px;
                            font-size: 12px;
                            font-weight: 400;
                            font-family: Inter-Regular;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 24px;
                        }
                    }
                }
                .sign-btn{
                    width: 100%;
                    margin-top: .16rem;
                }
            }
            h3{
                padding: 0 .24rem;
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: .2rem;
                color: #FFFFFF;
                line-height: .24rem;
                margin-bottom: .16rem;
            }
        }
        .sign-btn{
            width: 100%;
            height: 48px;
            background: rgba(255,121,87,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #FF7957;
            cursor: pointer;
            user-select: none;
            font-size: 16px;
            color: #FF7957;
        }
        .mar80{
            margin: .48rem 0;
        }
        .marSwiperbtm{
            margin: .3rem 0 .48rem 0;
        }
    }
}
// deawer
.r-drawer{
    width: 3.3rem !important;
    .ant-drawer-body{
        background-color: #333333;
        padding: .24rem;
        .top-close{
            .drawerLogo{
                width: .52rem;
                height: .4rem;
            }
            .drawerClose{
                margin-left: auto;
                width: .24rem;
                height: .24rem;
            }
        }
        .drawer-tip{
            font-family: Inter;
            font-weight: 400;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            margin: .48rem 0 .3rem 0;
        }
        .r-primary_btn{
            width: 100%;
            height: .4rem;
            font-size: .14rem;
            font-family: Inter-Medium;
        }
    }
}
.come-in {
    opacity: 1;
    transform: translateY(150px);
    animation: come-in .2s ease forwards;
  }
  .come-in:nth-child(odd) {
    animation-duration: .2s;
  }
  
  @keyframes come-in {
    100% {
      transform: translateY(0);
    }
  }