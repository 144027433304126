.member-main{
    width: 1040px;
    margin: 0 auto;
    padding: 0 0 76px 0;
    align-items: flex-start !important;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 30px 0;
    }
    .member-content{
        .memtop-t{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            text-align: left;
            margin: 40px 0;
        }
        .price-card{
            max-width: 358px;
            user-select: none;
            cursor: pointer;
            width: 100%;
            min-height: 118px;
            background: rgba(255,255,255,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid rgba(255,255,255,0.2);
            padding: 12px;
            position: relative;
            &.active{
                border-color: #FF7957;
            }
            &:nth-child(2){
                margin:0 28px;
            }
            .price-date{
                font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                font-weight: bold;
                font-size: 14px;
                color: #5187EF;
                line-height: 16px;
            }
            .price-box{
                align-items: flex-start;
                color: #FFFFFF;
                margin: 4px 0 8px 0;
                .priceLef{
                    font-size: 14px;
                    font-family: Inter-Medium;
                    margin: 2px 3px 0 0;
                }
                .priceNum{
                    font-size: 24px;
                    font-family: Inter-Bold;
                }
            }
            .price-pts{
                margin-left: auto;
                font-family: Inter-SemiBold;
                font-weight: normal;
                font-size: 16px;
                color: #FF7957;
                line-height: 24px;
            }
            .price-tip{
                font-family: Inter;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                opacity: .7;
                line-height: 20px;
            }
            .discountBox{
                position: absolute;
                top: 0;
                right: 0;
                width: 119px;
                height: 24px;
                background: #5187EF;
                border-radius: 0px 6px 0px 8px;
                font-size: 12px;
                font-weight: 400;
                font-family: Inter-Regular;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
            }
        }
        .pay-btn{
            width: 460px;
            margin: 100px auto 20px auto;
        }
        .other-btn{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 13px;
            color: #FF7957;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
            user-select: none;
        }
    }
}
.member-confirm-main{
    padding: 200px 0 106px 0;
    width: 100%;
    align-items: flex-start !important;
    .logo-box{
        width: 460px;
        height: 340px;
        margin-right: 120px;
    }
    .order-rightBox{
        width: 460px;
        .order-tit{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            text-align: left;
        }
        .rouBox{
            width: 100%;
            height: 20px;
        }
        .order-tip{
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 23px;
            text-align: left;
        }
        .orderTop-tip{
            margin: 12px 0 24px;
            opacity: .7;
        }
        .orderInfo-box{
            .order-msg{
                font-family: Inter-SemiBold;
                margin-left: auto;
            }
            .marb12{
                margin-bottom: 12px;
            }
            .marb20{
                margin-bottom: 20px;
            }
            .order-line{
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, .5);
                margin: 20px 0;
            }
        }
        .order-opeationBox{
            justify-content: space-between;
            margin-top: 40px;
            .w220{
                width: 220px;
            }
            .w100{
                width: 100%;
            }
        }
        // pay
        .pay_content {
            font-size: 16px;
            .pay_btn {
                width: 460px;
                height: 46px;
                background: rgba(255,255,255,0.1);
                border: 1px solid rgba(255,255,255,0.3);
                border-radius: 8px;
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                cursor: pointer;
                user-select: none;
            }
            .apppay {
                background-color: #FFFFFF;
                border: 1px solid #FFFFFF;
                color: #000000;
            }
            .mar17 {
                margin-top: 17px;
            }
            .primaryPay_input {
                padding: 0px 16px;
                width: 100%;
                height: 48px;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                font-size: 16px;
                font-weight: 400;
                outline: none;
                border-radius: 8px;
                font-family: var(--primary-light-font);
                font-weight: 500;
                color: #ffffff;
                caret-color:#fff;
                &::placeholder {
                    color: rgba(255, 255, 255, .7);
                    font-family: Inter-Regular;
                }
                
            }
            .pay_tips {
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: left;
                margin: 20px 0 8px 0;
            }
            .mar12_new {
                margin-left: 12px;
            }
        }
        // success
        .member-msg{
            font-family: Inter-Medium;
        }
        .price-card{
            width: 100%;
            min-height: auto;
            background: rgba(255,255,255,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid rgba(255,255,255,0.2);
            padding: 12px;
            position: relative;
            margin: 20px 0 24px 0;
            .price-date{
                font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                font-weight: bold;
                font-size: 14px;
                color: #5187EF;
                line-height: 16px;
            }
            .price-box{
                align-items: flex-start;
                color: #FFFFFF;
                margin: 4px 0 8px 0;
                .priceLef{
                    font-size: 14px;
                    font-family: Inter-Medium;
                    margin: 2px 3px 0 0;
                }
                .priceNum{
                    font-size: 24px;
                    font-family: Inter-Bold;
                }
            }
            .price-pts{
                margin-left: auto;
                font-family: Inter-SemiBold;
                font-weight: normal;
                font-size: 16px;
                color: #FF7957;
                line-height: 24px;
            }
            .price-tip{
                font-family: Inter;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                opacity: .7;
                line-height: 20px;
                &.mar20{
                    margin-top: 16px;
                }
            }
        }
        .sub-card{
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            &:nth-child(2){
                margin: 0 17px;
            }
            .subCard_top{
                padding: 20px;
                position: relative;
                background: rgba(255,255,255,0.05);
                .sub-icon{
                    width: 72px;
                    height: 72px;
                    margin-right: 12px;
                }
                .sub-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 32px;
                }
                .sub-price{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #5187EF;
                    margin-top: 14px;
                }
                .maxIcBox{
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    margin-left: 6px;
                    opacity: .7;
                }
                .mostBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 116px;
                    height: 32px;
                    background: #FF7957;
                    border-radius: 0px 8px 0px 8px;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: Inter-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 32px;
                }
            }
            .sub_content{
                padding: 20px;
                .subInfo_box{
                    margin-bottom: 16px;
                    align-items: flex-start;
                }
                .check_icon{
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin:2px 8px 0 0;
                    background: url('../../../assets/img/home/checked.png') no-repeat;
                    background-size: contain;
                }
                .sub_tip{
                    font-family: Inter-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    opacity: .7;
                }
                .sub_tipBtm{
                    width: 100%;
                    padding: 8px 90px 8px 8px;
                    background: #70402f;
                    border-radius: 6px 6px 6px 6px;
                    margin: 0;
                    .tip_icon{
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin:2px 8px 0 0;
                        background: url('../../../assets/img/home/tip.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
.mb-main{
    .member-main{
        padding: 0 0 .32rem 0;
        position: relative;
        width: 100%;
        z-index: 2;
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .member-content{
            padding: 0 .24rem;
            .memtop-t{
                text-align: center;
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: .24rem;
                color: #FFFFFF;
                line-height: .32rem;
                margin: .32rem 0 .2rem 0;
            }
            .memList-box{
                display: block;
                padding-bottom: 1.1rem;
            }
            .price-card{
                width: 100%;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid rgba(255,255,255,0.2);
                padding: .12rem;
                position: relative;
                &.active{
                    border-color: #FF7957;
                }
                &:nth-child(2){
                    margin: .12rem 0;
                }
                .price-date{
                    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                    font-weight: bold;
                    font-size: .14rem;
                    color: #5187EF;
                    line-height: .16rem;
                }
                .price-box{
                    align-items: flex-start;
                    color: #FFFFFF;
                    margin: 4px 0 8px 0;
                    .priceLef{
                        font-size: .13rem;
                        font-family: Inter-Medium;
                        margin: 2px 3px 0 0;
                    }
                    .priceNum{
                        font-size: .2rem;
                        font-family: Inter-Bold;
                    }
                }
                .price-pts{
                    margin-left: auto;
                    font-family: Inter-SemiBold;
                    font-weight: normal;
                    font-size: .14rem;
                    color: #FF7957;
                    line-height: .2rem;
                }
                .price-tip{
                    font-family: Inter;
                    font-weight: 400;
                    font-size: .12rem;
                    color: #FFFFFF;
                    opacity: .7;
                    line-height: .2rem;
                }
                .discountBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 119px;
                    height: 24px;
                    background: #5187EF;
                    border-radius: 0px 6px 0px 8px;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Inter-Regular;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 24px;
                }
            }
            .opeation-box{
                display: flex;
                flex-direction: column-reverse;
                padding-top: .2rem;
                position: fixed;
                bottom: .32rem;
                width: calc(100% - .48rem);
            }
            .pay-btn{
                width: 100%;
                margin: .2rem 0 0 0;
            }
            .other-btn{
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: 13px;
                color: #FF7957;
                line-height: 20px;
                text-align: center;
                cursor: pointer;
                user-select: none;
            }
        }
    }
    .member-confirm-main{
        padding: 0 0 .32rem 0;
        position: relative;
        width: 100%;
        z-index: 2;
        &::after{
            content: '';
            position: fixed;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            background: url('../../../assets/img/mb-bg.png') no-repeat;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .order-rightBox{
            width: 100%;
            padding:  0 .24rem .88rem .24rem;
            position: relative;
            z-index: 2;
            .order-tit{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: .24rem;
                color: #FFFFFF;
                line-height: .32rem;
                text-align: center;
                margin-top: .32rem;
            }
            .rouBox{
                width: 100%;
                height: .16rem;
            }
            .order-tip{
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                text-align: left;
            }
            .orderTop-tip{
                font-size: .12rem;
                line-height: .16rem;
                margin: .12rem 0 .16rem;
                opacity: .7;
                text-align: center;
            }
            .orderInfo-box{
                .order-msg{
                    font-family: Inter-SemiBold;
                    margin-left: auto;
                    font-weight: bold;
                }
                .marb12{
                    margin-bottom: .1rem;
                }
                .marb20{
                    margin-bottom: .16rem;
                }
                .order-line{
                    width: 100%;
                    height: 1px;
                    background-color: rgba(255, 255, 255, .5);
                    margin: .16rem 0;
                }
            }
            .order-opeationBox{
                position: fixed;
                width: calc(100% - .48rem);
                bottom: .32rem;
                flex-direction: column-reverse;
                margin-top: 40px;
                .w220{
                    width: 100%;
                }
                .r-gray_btn{
                    margin-top: .2rem;
                }
            }
            .pay_content {
                padding: 40px 0;
                .pay_btn {
                    width: 100%;
                    height: .46rem;
                    background: rgba(255,255,255,0.1);
                    border: 1px solid rgba(255,255,255,0.3);
                    border-radius: 8px;
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .14rem;
                    color: #FFFFFF;
                    cursor: pointer;
                    user-select: none;
                }
                .apppay {
                    background-color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                    color: #000000;
                }
                .mar17 {
                    margin-top: .16rem;
                }
                .primaryPay_input {
                    padding: 0px 16px;
                    width: 100%;
                    height: 48px;
                    background: rgba(255,255,255,0.1);
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid rgba(255,255,255,0.3);
                    font-size: 14px;
                    font-weight: 400;
                    outline: none;
                    border-radius: 8px;
                    font-family: var(--primary-light-font);
                    font-weight: 500;
                    color: #ffffff;
                    caret-color:#fff;
                    &::placeholder {
                        color: rgba(255, 255, 255, .7);
                        font-family: Inter-Regular;
                    }
                    
                }
                .pay_tips {
                    font-family: Inter-Medium, Inter;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    text-align: left;
                    margin: 16px 0 8px 0;
                }
                .mar12_new {
                    margin-left: 12px;
                }
            }
            // success
            .member-msg{
                font-family: Inter-Medium;
                margin-top: .24rem;
            }
            .price-card{
                width: 100%;
                // min-height: 1.1rem;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid rgba(255,255,255,0.2);
                padding: .12rem;
                position: relative;
                margin: .12rem 0 .16rem 0;
                .price-date{
                    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                    font-weight: bold;
                    font-size: .14rem;
                    color: #5187EF;
                    line-height: .16rem;
                }
                .price-box{
                    align-items: flex-start;
                    color: #FFFFFF;
                    margin: 4px 0 8px 0;
                    .priceLef{
                        font-size: .13rem;
                        font-family: Inter-Medium;
                        margin: 2px 3px 0 0;
                    }
                    .priceNum{
                        font-size: .2rem;
                        font-family: Inter-Bold;
                    }
                }
                .price-pts{
                    margin-left: auto;
                    font-family: Inter-SemiBold;
                    font-weight: normal;
                    font-size: .14rem;
                    color: #FF7957;
                    line-height: .2rem;
                }
                .price-tip{
                    font-family: Inter;
                    font-weight: 400;
                    font-size: .12rem;
                    color: #FFFFFF;
                    opacity: .7;
                    line-height: .2rem;
                }
                .discountBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 119px;
                    height: 24px;
                    background: #5187EF;
                    border-radius: 0px 6px 0px 8px;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Inter-Regular;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 24px;
                }
            }
            .sub-card{
                width: 100%;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                .subCard_top{
                    padding: .2rem;
                    position: relative;
                    background: rgba(255,255,255,0.05);
                    .sub-icon{
                        width: .72rem;
                        height: .72rem;
                        margin-right: .12rem;
                    }
                    .sub-tit{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: .2rem;
                        color: #FFFFFF;
                        line-height: .24rem;
                    }
                    .sub-price{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: .32rem;
                        color: #5187EF;
                        margin-top: 8px;
                    }
                    .maxIcBox{
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 20px;
                        margin-left: 6px;
                        opacity: .7;
                    }
                    .mostBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1.16rem;
                        height: .32rem;
                        background: #FF7957;
                        border-radius: 0px 8px 0px 8px;
                        font-size: .12rem;
                        font-weight: 500;
                        font-family: Inter-Medium;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: .32rem;
                    }
                }
                .sub_content{
                    padding: .2rem;
                    .subInfo_box{
                        margin-bottom: .16rem;
                        align-items: flex-start;
                    }
                    .check_icon{
                        width: .16rem;
                        min-width: .16rem;
                        height: .16rem;
                        margin:.01rem .08rem 0 0;
                    }
                    .sub_tip{
                        font-family: Inter-Regular;
                        font-weight: 400;
                        font-size: .12rem;
                        color: #FFFFFF;
                        line-height: .16rem;
                        opacity: .7;
                    }
                    .sub_tipBtm{
                        padding: .08rem;
                        width: 100%;
                        background: #70402f;
                        border-radius: 6px 6px 6px 6px;
                        margin: 0;
                        .tip_icon{
                            width: .16rem;
                            min-width: .16rem;
                            height: .16rem;
                            margin:.02rem .08rem 0 0;
                        }
                    } 
                    .homeSign-btn{
                        margin-top: .16rem;
                        width: 100%;
                        height: 40px;
                        font-size: .14rem;
                    }
                }
            }
        }
    }
}