/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2024-03-07 16:20:38
 * Copyright © Techne All rights reserved
 */
// deawer
.r-drawer{
    height: calc(var(--app-height));
    // overflow: hidden;
    box-sizing: border-box;
    width: 3.3rem !important;
    background-color: #333333 !important;
    padding: .24rem;
    .top-close{
            .drawerLogo{
                width: .52rem;
                height: .4rem;
            }
            .drawerClose{
                margin-left: auto;
                width: .24rem;
                height: .24rem;
            }
    }
    .drawer-tip{
            font-family: Inter;
            font-weight: 400;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            margin: .48rem 0 .3rem 0;
    }
    .r-primary_btn{
            width: 100%;
            height: .4rem;
            font-size: .14rem;
            font-family: Inter-Medium;
    }
    .navDrawer-box{
        height: calc(var(--app-height) - .88rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .sub_tip{
            display: block;
            font-family: Inter, Inter;
            font-weight: 400;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            margin-bottom:.5rem;
        }
        .navUl{
            margin-top: .08rem;
            .navItem{
                width: 100%;
                padding: .17rem 0;
                font-family: Inter-SemiBold, Inter;
                font-weight: normal;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                user-select: none;
                cursor: pointer;
                position: relative;
                transition: all .2s;
                margin: 0;
                &::after{
                    display: none;
                }
                &.active{
                    color: #FF7957;
                }
            }
        }
    }
}
.rv-toast.rv-popup{
    background-color: rgba(255, 255, 255, 0.2) !important;
}