.prize-main{
    width: 1040px;
    margin: 0 auto;
    padding: 0 0 76px 0;
    align-items: flex-start !important;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 30px 0;
    }
    .prize-content{
        .profile-numBox{
            .statistics-card{
                width: 398px;
                padding: 20px;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.7);
                line-height: 24px;
                text-align: left;
                background: #5187EF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #5187EF;
                letter-spacing: -0.2px;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 94px;
                    height: 100%;
                    background: url('../../../../assets/img/gift.png') no-repeat;
                    background-size: contain;
                }
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 32px;
                    margin-top: 10px;
                }
                .mar10{
                    margin: 10px 0;
                }
            }
        }
        .bronze-box{
            margin: 80px 0;
            width: 100%;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            padding: 20px;
            .bronze-h1{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 32px;
                color: #FFFFFF;
                line-height: 40px;
            }
            .bronzeList-box{
                margin-top: 20px;
                flex-wrap: wrap;
                .bronzeCatd{
                    width: 310px;
                    min-height: 112px;
                    background: rgba(255,255,255,0.05);
                    border-radius: 6px 6px 6px 6px;
                    border: 1px solid rgba(255,255,255,0.2);
                    padding: 12px;
                    // margin:0 34px 24px 0;
                    // &:nth-child(3n){
                    //     margin-right: 0;
                    // }
                    .prize-name{
                        font-family: Inter-Bold, Inter;
                        font-weight: bold;
                        font-size: 14px;
                        color: #5187EF;
                        line-height: 20px;
                        width: 180px;
                        max-width: 180px;
                        word-break: break-word;
                    }
                    .mart16{
                        margin-top: 16px;
                    }
                    .prize-points{
                        font-family: Inter-Bold, Inter;
                        font-weight: bold;
                        font-size: 20px;
                        color: #FF7957;
                        line-height: 24px;
                        .pointTip{
                            font-size: 14px;
                            font-family: Inter-Medium;
                            margin-left: 5px;
                        }
                    }
                    .prizeEnter-btn{
                        width: 76px;
                        height: 32px;
                        background: #FF7957;
                        border-radius: 8px 8px 8px 8px;
                        border: 1px solid #FF7957;
                        font-size: 16px;
                        font-family: Inter-SemiBold;
                        color: #FFFFFF;
                        user-select: none;
                        cursor: pointer;
                        margin-left: auto;
                    }
                    .prizeCheckout-btn{
                        width: 110px;
                        height: 32px;
                        background: rgba(255,255,255,0.05);
                        border-radius: 8px 8px 8px 8px;
                        font-size: 16px;
                        font-family: Inter-Regular;
                        color: rgba(255, 255, 255, .7);
                        user-select: none;
                        cursor: pointer;
                        margin-left: auto;
                    }
                }
                .prize_tipBtm{
                    margin-top: 24px;
                    width: 540px;
                    padding:0 8px;
                    height: 36px;
                    background: #70402f;
                    border-radius: 6px 6px 6px 6px;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: left;
                    .tip_icon{
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin-right: 10px;
                        background: url('../../../../assets/img/home/tip.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
        .member-box{
            .mem-topbox{
                margin-bottom: 40px;
                .lock-icon{
                    width: 68px;
                    height: 68px;
                    background: url('../../../../assets/img/lock.png') no-repeat;
                    background-size: contain;
                    margin-right: 20px;
                }
                .mem-t{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: 32px;
                    color: #FFFFFF;
                    line-height: 40px;
                    margin-bottom: 12px;
                }
                .mem-tip{
                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 23px;
                    text-align: left;
                    opacity: .7;
                }
            }
            .upgrade-btn{
                margin-top: 50px;
                width: 460px;
            }
        }
    }
}
.mb-main{
    .prize-main{
        padding: 0 0 .32rem 0;
        position: relative;
        width: 100%;
        z-index: 2;
        &::before{
            content: '';
            position: fixed;
            width: 114px;
            height: 130px;
            top: 0;
            right: 0;
            background: url('../../../../assets/img/nav-top-bg.png') no-repeat;
            z-index: 1;
            background-size: cover;
        }
        &::after{
            content: '';
            position: fixed;
            top: 330px;
            left: 0;
            width: 100%;
            height: calc(var(--app-height));
            background: url('../../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .prize-content{
            position: relative;
            z-index: 2;
            padding-top: 8px;
            width: 100%;
        }
        .profile-numBox{
            padding: 0 .24rem;
            .statistics-card{
                width: 100%;
                padding: 20px;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: .2rem;
                text-align: left;
                background: #5187EF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #5187EF;
                letter-spacing: -0.2px;
                position: relative;
                text-align: center;  
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    background: url('../../../../assets/img/gift.png') no-repeat;
                    background-size: cover;
                    border-radius: 0 8px 9px 0;
                }
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                    margin-top: .1rem;
                }
                .mar10{
                    margin: 10px 0;
                }
            }
        }
        .pad24{
            padding: 0 .24rem;
        }
        .bronze-box{
            margin: .16rem 0 .32rem 0;
            width: 100%;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            padding: 20px;
            .bronze-h1{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: .2rem;
                color: #FFFFFF;
                line-height: .24rem;
            }
            .bronzeList-box{
                margin-top: 24px;
                display: block;
                .bronzeCatd{
                    width: 100%;
                    min-height: auto;
                    background: rgba(255,255,255,0.05);
                    border-radius: 6px 6px 6px 6px;
                    border: 1px solid rgba(255,255,255,0.2);
                    padding: 12px;
                    margin:0 0 .24rem 0;
                    .prize-name{
                        font-family: Inter-Bold, Inter;
                        font-weight: bold;
                        font-size: .12rem;
                        color: #5187EF;
                        line-height: 20px;
                        width: 1.6rem;
                    }
                    .mart16{
                        margin-top: .16rem;
                    }
                    .prize-points{
                        font-family: Inter-Bold, Inter;
                        font-weight: bold;
                        font-size: .2rem;
                        color: #FF7957;
                        line-height: .24rem;
                        .pointTip{
                            font-size: .14rem;
                            font-family: Inter-Medium;
                            margin-left: 5px;
                        }
                    }
                    .prizeEnter-btn{
                        width: .76rem;
                        height: .28rem;
                        background: #FF7957;
                        border-radius: 8px 8px 8px 8px;
                        border: 1px solid #FF7957;
                        font-size: .14rem;
                        font-family: Inter-Medium;
                        color: #FFFFFF;
                        user-select: none;
                        cursor: pointer;
                        margin-left: auto;
                    }
                    .prizeCheckout-btn{
                        width: 1.02rem;
                        height: .28rem;
                        background: rgba(255,255,255,0.05);
                        border-radius: 8px 8px 8px 8px;
                        font-size: .14rem;
                        font-family: Inter-Regular;
                        color: rgba(255, 255, 255, .7);
                        user-select: none;
                        cursor: pointer;
                        margin-left: auto;
                    }
                }
                .prize_tipBtm{
                    align-items: flex-start;
                    width: 100%;
                    padding:.08rem;
                    height: .48rem;
                    background: #70402f;
                    border-radius: 6px 6px 6px 6px;
                    font-family: Inter-Regular, Inter;
                    font-weight: 400;
                    font-size: .12rem;
                    color: rgba(255, 255, 255, .7);
                    text-align: left;
                    .tip_icon{
                        width: .16rem;
                        min-width: .16rem;
                        height: .16rem;
                        margin-right: .1rem;
                        background: url('../../../../assets/img/home/tip.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
        .member-box{
            .mem-topbox{
                padding: 0 .24rem;
                margin-bottom: .24rem;
                display: block;
                .lock-icon{
                    display: block;
                    width: .56rem;
                    height: .56rem;
                    background: url('../../../../assets/img/lock.png') no-repeat;
                    background-size: contain;
                    margin: 0 auto;
                }
                .mem-t{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .2rem;
                    color: #FFFFFF;
                    line-height: .24rem;
                    margin: 4px 0;
                    text-align: center;
                }
                .mem-tip{
                    text-align: center;
                    font-family: Inter, Inter;
                    font-weight: 400;
                    font-size: .14rem;
                    color: #FFFFFF;
                    line-height: .2rem;
                    opacity: .7;
                }
            }
            .upgrade-btn{
                margin-top: .2rem;
                width: 100%;
            }
        }
    }
}