.membercard-box{
    .memberOption-box{
        margin-bottom: 30px;
        justify-content: space-between;
        padding: 0;
        .member-card{
            max-width: 358px;
            padding: 20px;
            flex: 1;
            min-height: 570px;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            position: relative;
            &:nth-child(2){
                background: rgba(81, 135, 239, 0.30);
                margin: 0 28px;
            }
            .mem-tit{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                margin-bottom: 16px;
            }
            .mostBox{
                position: absolute;
                top: 0;
                right: 0;
                width: 116px;
                height: 32px;
                background: #FF7957;
                border-radius: 0px 8px 0px 8px;
                font-size: 12px;
                font-weight: 500;
                font-family: Inter-Medium;
                color: #FFFFFF;
                text-align: center;
                line-height: 32px;
            }
            .price-card{
                width: 100%;
                min-height: 118px;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid rgba(255,255,255,0.2);
                padding: 12px;
                position: relative;
                &.mar12{
                    margin: 12px 0;
                }
                .price-date{
                    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                    font-weight: bold;
                    font-size: 14px;
                    color: #5187EF;
                    line-height: 16px;
                }
                .price-box{
                    align-items: flex-start;
                    color: #FFFFFF;
                    margin: 4px 0 8px 0;
                    .priceLef{
                        font-size: 14px;
                        font-family: Inter-Medium;
                        margin: 2px 3px 0 0;
                    }
                    .priceNum{
                        font-size: 24px;
                        font-family: Inter-Bold;
                    }
                }
                .price-pts{
                    margin-left: auto;
                    font-family: Inter-SemiBold;
                    font-weight: normal;
                    font-size: 16px;
                    color: #FF7957;
                    line-height: 24px;
                }
                .price-tip{
                    font-family: Inter;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    opacity: .7;
                    line-height: 20px;
                }
                .discountBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 119px;
                    height: 24px;
                    background: #5187EF;
                    border-radius: 0px 6px 0px 8px;
                    font-size: 12px;
                    font-weight: 400;
                    font-family: Inter-Regular;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 24px;
                }
            }
        }
        .sign-btn,.skip-btn{
            width: 288px;
            height: 48px;
            background: rgba(255,121,87,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #FF7957;
            cursor: pointer;
            user-select: none;
            font-size: 16px;
            margin-top: 16px;
            color: #FF7957;
        }
        .skip-btn{
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            color: #FFFFFF;
        }
    }
    .free-member{
        .member-card {
            height: auto;
            min-height: auto;
        }
        .free-active{
            background: #402d26;
            border-color: #402d26;
            cursor: not-allowed;
        }
        .marb20{
            margin-bottom: 16px;
        }
    }
    .minMar18{
        flex: 1;
        max-width: initial !important;
        &:nth-child(2){
            margin: 0 18px !important;
        }
    }
}

.mb-main{
    .membercard-box{
        .memberOption-box{
            margin-bottom: .30rem;
            justify-content: space-between;
            padding:0 .24rem .5rem .24rem;
            .member-card{
                width: 3.08rem;
                padding: .2rem;
                min-height: auto;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                position: relative;
                &.other-color{
                    background: rgba(81, 135, 239, 0.30);
                }
                .mem-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: .2rem;
                    color: #FFFFFF;
                    line-height: .24rem;
                    margin-bottom: .16rem;
                }
                .mostBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1.19rem;
                    height: .32rem;
                    background: #FF7957;
                    border-radius: 0px 8px 0px 8px;
                    font-size: .12rem;
                    font-weight: 500;
                    font-family: Inter-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: .32rem;
                }
                .price-card{
                    width: 100%;
                    // min-height: 1.1rem;
                    background: rgba(255,255,255,0.05);
                    border-radius: 6px 6px 6px 6px;
                    border: 1px solid rgba(255,255,255,0.2);
                    padding: .12rem;
                    position: relative;
                    &.mar12{
                        margin: .12rem 0;
                    }
                    .price-date{
                        font-family: Plus Jakarta Sans, Plus Jakarta Sans;
                        font-weight: bold;
                        font-size: .14rem;
                        color: #5187EF;
                        line-height: .16rem;
                    }
                    .price-box{
                        align-items: flex-start;
                        color: #FFFFFF;
                        margin: 4px 0 8px 0;
                        .priceLef{
                            font-size: .13rem;
                            font-family: Inter-Medium;
                            margin: 2px 3px 0 0;
                        }
                        .priceNum{
                            font-size: .2rem;
                            font-family: Inter-Bold;
                        }
                    }
                    .price-pts{
                        margin-left: auto;
                        font-family: Inter-SemiBold;
                        font-weight: normal;
                        font-size: .14rem;
                        color: #FF7957;
                        line-height: .2rem;
                    }
                    .price-tip{
                        font-family: Inter;
                        font-weight: 400;
                        font-size: .12rem;
                        color: #FFFFFF;
                        opacity: .7;
                        line-height: .2rem;
                    }
                    .discountBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 119px;
                        height: 24px;
                        background: #5187EF;
                        border-radius: 0px 6px 0px 8px;
                        font-size: 12px;
                        font-weight: 400;
                        font-family: Inter-Regular;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 24px;
                    }
                    .marb20{
                        margin-bottom: .14rem;
                    }
                }
            }
            .sign-btn,.skip-btn{
                width: 100%;
                margin-top: .16rem;
            }
        }
        .noBtm-mar{
            margin: 0;
        }
    }
    .introduction-card{
        .swiper-pagination-bullets.swiper-pagination-horizontal{
            bottom: 8px;
        }
    }
}