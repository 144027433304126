.Category-main{
    // padding-top: 64px;
    .logo-box{
        width: 158px;
        height: 124px;
        margin: 20px 0 70px 0;
    }
    .cateContent-box{
        padding: 0 200px 62px 200px;
        .content-tit{
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 38px;
            text-align: center;
        }
        .content-col{
            color: #5187EF;
            font-family: Inter-Regular;
            text-align: center;
            font-style: italic;
            margin-left: 5px;
        }
        .top-tip{
            font-family: Inter-Regular;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 23px;
            text-align: center;
            margin-top: 8px;
            opacity: .7;
        }
        .category-listBox{
            width: 604px;
            margin: 70px auto 0 auto;
            flex-wrap: wrap;
            justify-content: space-between;
            .category-box{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 52px;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                margin:0 0 20px 0;
                width: 292px;
                padding:0 12px;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                cursor: pointer;
                user-select: none;
                word-break: break-word;
                .category-icon{
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
                &.selected{
                    border-color: #FF7957;
                }
            }
        }
        .finish-btn,.skip-btn{
            width: 292px;
            height: 48px;
            background: #FF7957;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #FF7957;
            cursor: pointer;
            user-select: none;
            font-size: 16px;
            margin-top: 20px;
            color: #FFFFFF;
            &.disabled{
                opacity: 1;
                background-color: #803D2B;
                border: 1px solid #803D2B;
                color: #808080;
                cursor: no-drop;
            }
        }
        .skip-btn{
            background: #191919;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #5E5E5E;
        }
        .marB20{
            margin-bottom: 20px;
        }
    }
}

.mb-main{
    .Category-main{
        // 
        padding: 0;
        position: relative;
        .logo-box{
            display: none;
        }
        .cateContent-box{
            padding: 0;
            position: relative;
            z-index: 2;
            .content-top{
                width: 100%;
                // height: 2.12rem;
                background: rgba(255,255,255,0.1);
                border-radius: 0px 0px 0px 0px;
                border: none;
                border-bottom: 1px solid rgba(255,255,255,0.3);
                padding: .4rem 1.02rem .24rem .24rem;
                position: relative;
                &::after{
                    content: '';
                    width: 1.66rem;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: url('../../../assets/img/cate-page-bg.png') no-repeat;
                    background-size: cover;
                    z-index: 1;
                }
            }
            .content-tit{
                padding: 0;
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: .24rem;
                color: #FFFFFF;
                line-height: .32rem;
                text-align: center;
                display: block;
                text-align: left;
            }
            .content-col{
                color: #5187EF;
                font-family: Inter-Regular;
                text-align: center;
                font-style: italic;
                margin:.1rem 0 0 0;
                text-align: left;
                display: block;
            }
            .top-tip{
                padding: 0;
                font-family: Inter-Regular;
                font-weight: 400;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                text-align: center;
                margin-top: 0.1rem;
                opacity: .7;
                text-align: left;
            }
            .category-listBox{
                width: 100%;
                flex-wrap: wrap;
                margin: 0;
                justify-content: space-between;
                padding: .24rem;
                .category-box{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-height: .48rem;
                    background: rgba(255,255,255,0.1);
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid rgba(255,255,255,0.3);
                    margin:0 0 .12rem 0;
                    width: 1.65rem;
                    padding:0 .12rem;
                    font-size: .12rem;
                    line-height: .14rem;
                    letter-spacing: -.3px;
                    word-break: break-word;
                    .category-icon{
                        width: .24rem;
                        min-width: .24rem;
                        height: .24rem;
                        margin-right: 4px;
                        border-radius: 3px;
                    }
                    &.selected{
                        border-color: #FF7957;
                    }
                }
                .finish-btn,.skip-btn{
                    width: 1.65rem;
                }
            }
            .cate_show_box{
                padding:.24rem .24rem 0.9rem .24rem;
            }
            .marB20{
                margin-bottom: .2rem;
            }
        }
    }
    .fixed-btm{
        position: fixed;
        bottom: 0;
    }
}