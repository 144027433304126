.enterSuccess-main{
    padding: 200px 0 152px 0;
    width: 100%;
    align-items: flex-start !important;
    .logo-box{
        width: 460px;
        height: 340px;
        margin-right: 120px;
    }
    .profile-numBox{
        .statistics-card{
            width: 460px;
            padding: 20px;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 24px;
            text-align: left;
            background: #5187EF;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #5187EF;
            letter-spacing: -0.2px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 94px;
                height: 100%;
                background: url('../../../../assets/img/gift.png') no-repeat;
                background-size: contain;
            }
            .sta-num{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                margin-top: 10px;
            }
            .mar10{
                margin: 10px 0;
            }
        }
    }
    .succ-tip{
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
        opacity: .7;
    }
    .enter-box{
        .succ-icon{
            margin: 40px 0 16px 0;
            width: 96px;
            height: 96px;
            background: url('../../../../assets/img/succ.png') no-repeat;
            background-size: contain;
        }
        .succ-tit{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            margin-bottom: 8px;
        }
        .prize-statusBox{
            margin: 24px 0 40px 0;
            width: 460px;
            min-height: 114px;
            background: rgba(255,255,255,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid rgba(255,255,255,0.2);
            padding: 20px;
            .winuser-box{
                font-family: Inter-bold, Inter;
                font-weight: bold;
                font-size: 32px;
                color: #5187EF;
                line-height: 40px;
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }
    .question-opeationBox{
        .w220{
            margin-top: 12px;
            width: 460px;
        }
    }
}
.mb-main{
    .enterSuccess-main{
        padding: 0 .24rem .32rem .24rem;
        position: relative;
        width: 100%;
        z-index: 2;
        min-height: calc(var(--app-height) - 78px);
        &::before{
            content: '';
            position: fixed;
            width: 114px;
            height: 130px;
            top: 0;
            right: 0;
            background: url('../../../../assets/img/nav-top-bg.png') no-repeat;
            z-index: 1;
            background-size: cover;
        }
        &::after{
            content: '';
            position: fixed;
            top: 330px;
            left: 0;
            width: 100%;
            height: calc(var(--app-height));
            background: url('../../../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .logo-box{
            display: none;
        }
        .profile-rightBox{
            // position: relative;
            z-index: 2;
            padding-top: 8px;
            width: 100%;
            height: 100%;
        }
        .profile-numBox{
            .statistics-card{
                width: 100%;
                padding: 20px;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: .2rem;
                text-align: left;
                background: #5187EF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #5187EF;
                letter-spacing: -0.2px;
                position: relative;
                text-align: center;  
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    background: url('../../../../assets/img/gift.png') no-repeat;
                    background-size: cover;
                    border-radius: 0 8px 9px 0;
                }
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                    margin-top: .1rem;
                }
                .mar10{
                    margin: 10px 0;
                }
            }
        }
        .succ-tip{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            text-align: center;
            opacity: .7;
        }
        .enter-box{
            .succ-icon{
                margin: .24rem 0 .16rem 0;
                width: .96rem;
                height: .96rem;
                background: url('../../../../assets/img/succ.png') no-repeat;
                background-size: contain;
            }
            .succ-tit{
                font-family: Inter-Bold, Inter;
                font-weight: normal;
                font-size: .16rem;
                color: #FFFFFF;
                line-height: .24rem;
                text-align: center;
                margin-bottom: .08rem;
            }
            .prize-statusBox{
                margin: .24rem 0 1.02rem 0;
                width: 100%;
                min-height: auto;
                background: rgba(255,255,255,0.05);
                border-radius: 6px 6px 6px 6px;
                border: 1px solid rgba(255,255,255,0.2);
                padding: 20px;
                .winuser-box{
                    font-family: Inter-bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #5187EF;
                    line-height: .32rem;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }
        .question-opeationBox{
            width: calc(100% - .48rem);
            position: absolute;
            bottom: .24rem;
            .w220{
                margin-top: .16rem;
                width: 100%;
                height: .4rem;
                font-size: .14rem;
                font-size: Inter-Medium;
                font-weight: 500;
            }
        }
    }
}