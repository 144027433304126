.number-input_box{
    display: flex;
    align-items: center;
    width: 94px;
    height: 30px;
    background: rgba(255,255,255,0.05);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid rgba(255,255,255,0.1);
    .number-box{
        width: 38px;
        height: 100%;
        line-height: 30px;
        border-left: 1px solid rgba(255,255,255,0.1);
        border-right: 1px solid rgba(255,255,255,0.1);
        font-family: Inter-SemiBold, Inter;
        font-size: 14px;
        color: #5187EF;
        text-align: center;
        user-select: none;
        &.disabled{
            color: rgba(255, 255, 255, .5);
        }
    }
    .jan-icon,.jia-icon{
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    .jan-icon{
        background: url('../../assets/img/jan.png') no-repeat;
        background-size: contain;
    }
    .jia-icon{
        background: url('../../assets/img/jia.png') no-repeat;
        background-size: contain;
    }
    .numberIcon-box{
        padding: 0 6px;
        cursor: pointer;
        &.disabled{
            cursor: not-allowed;
            .jan-icon{
                background: url('../../assets/img/jan-d.png') no-repeat;
                background-size: contain;
                cursor: not-allowed;
            }
            .jia-icon{
                background: url('../../assets/img/jia-d.png') no-repeat;
                background-size: contain;
                cursor: not-allowed;
            }
        }
    }
}