.login-main{
    height: 100vh;
    overflow: hidden;
    .login-box{
        padding: 200px 200px 0 200px;
        align-items: flex-start;
        justify-content: center;
        .logo-box{
            width: 460px;
            height: 340px;
        }
        .login-form{
            margin-left: 120px;
            h1{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 32px;
                color: #FFFFFF;
                line-height: 40px;
                padding-bottom: 4px;
            }
            .form-tip{
                font-family: Inter-Medium;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 24px;
                margin: 20px 0 8px 0;
            }
            .phone-inputBox{
                position: relative;
                .left-iconBox{
                    position: absolute;
                    left: 0;
                    top: 0;
                    .auBox{
                        width: 56px;
                        height: 48px;
                        background: rgba(255,255,255,0.05);
                        border-radius: 8px 0px 0px 8px;
                        border-right: 1px solid rgba(255,255,255,0.3);
                    }
                    .phone-codebox{
                        font-family: Inter-Medium, Inter;
                        font-weight: 500;
                        font-size: 16px;
                        color: #FFFFFF;
                        line-height: 24px;
                        margin-left: 20px;
                    }
                }
                .phone-box{
                    padding-left: 128px;
                }
            }
            .forget-box{
                margin: 40px 0 20px 0;
                font-family: Inter-SemiBold, Inter;
                font-weight: normal;
                font-size: 16px;
                color: #FF7957;
                line-height: 24px;
                cursor: pointer;
                user-select: none;
            }
            .r-primary_btn{
                width: 460px;
            }
            .ageSel-input{
                position: relative;
                .r-age-date{
                    position: absolute;
                    width: 100%;
                    height: 48px;
                    left: 0;
                    opacity: 0;
                    // z-index: -10;
                }
                // .r-input:hover{
                //     .close_icon{
                //         display: block;
                //     }
                // }
            }
        }
        .mart40{
            margin-top: 40px;
        }
        .mart20{
            margin-top: 20px;
        }
    }
}

.mb-main{
    .login-main{
        height: calc(var(--app-height));
        overflow: hidden;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 78px;
            left: 0;
            bottom: 0;
            right: 0;
            background: url('../../assets/img/mb-bg.png') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        .login-box{
            padding: .32rem .24rem .24rem .24rem;
            position: relative;
            z-index: 2;
            .logo-box{
                width: 460px;
                height: 340px;
                display: none;
            }
            .login-form{
                width: 100vw;
                margin-left: 0;
                display: flex;
                flex-direction: column;
                h1{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                    padding-bottom: .02rem;
                    width: 100%;
                    text-align: center;
                }
                .form-tip{
                    font-family: Inter-Medium;
                    font-weight: 500;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin: .16rem 0 .08rem 0;
                    padding: 0;
                }
                .r-input{
                    width: 100%;
                    font-size: 14px;
                    .ant-input {
                        // background: #191919;
                        &::placeholder {
                            font-size: 14px;
                        }
                        font-size: 14px !important;
                        font-family: var(--primary-light-font);
                        font-weight: 500;
                        color: #ffffff;
                    }
                }
                .phone-inputBox{
                    position: relative;
                    font-size: 14px;
                    .left-iconBox{
                        position: absolute;
                        left: 0;
                        top: 0;
                        .auBox{
                            width: 56px;
                            height: 48px;
                            background: rgba(255,255,255,0.05);
                            border-radius: 8px 0px 0px 8px;
                            border-right: 1px solid rgba(255,255,255,0.3);
                        }
                        .phone-codebox{
                            font-family: Inter-Medium, Inter;
                            font-weight: 500;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 20px;
                            margin-left: 20px;
                        }
                    }
                    .phone-box{
                        padding-left: 128px;
                    }
                }
                .forget-box{
                    margin: .76rem 0 .2rem 0;
                    font-family: Inter-SemiBold, Inter;
                    font-weight: normal;
                    font-size: .13rem;
                    color: #FF7957;
                    line-height: .2rem;
                    cursor: pointer;
                    user-select: none;
                }
                .r-primary_btn{
                    width: 100%;
                }
            }
            .mart40{
                margin-top: 2.08rem;
            }
            .mart184{
                margin-top: 1.84rem;
            }
            .mart116{
                margin-top: 1.16rem;
            }
            .mart24{
                margin-top: .24rem;
            }
            .r-defaultBlickNew_btn{
                width: 100%;
            }
        }
    }
}
.swiper-sign{
    background: rgba(255,121,87,0.05) !important;
    height: 44px !important;
}