.SurveyRespond-main{
    padding: 200px 0 106px 0;
    width: 100%;
    align-items: flex-start !important;
    .logo-box{
        width: 460px;
        height: 340px;
        margin-right: 120px;
    }
    .profile-numBox{
        .statistics-card{
            width: 460px;
            padding: 20px;
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            line-height: 24px;
            text-align: left;
            background: #5187EF;
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #5187EF;
            letter-spacing: -0.2px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 94px;
                height: 100%;
                background: url('../../../../assets/img/gift.png') no-repeat;
                background-size: contain;
            }
            .sta-num{
                font-family: Inter-Bold, Inter;
                font-weight: bold;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 32px;
                margin-top: 10px;
            }
            .mar10{
                margin: 10px 0;
            }
        }
    }
    .question-num{
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 24px;
        margin: 40px 0 16px 0;
    }
    .cate_tip{
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
    }
    .category-boxM{
        margin: 16px 0 24px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 48px;
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        width: 460px;
        padding: 12px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        user-select: none;
        word-break: break-word;
        .category-icon{
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-right: 4px;
            border-radius: 4px;
        }
    }
    .marb8{
        margin-bottom: 8px;
    }
    .question-box{
        width: 460px;
        background: rgba(255,255,255,0.1);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 16px;
        .question-tit{
            font-family: Inter-SemiBold, Inter;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: left;
            margin-bottom: 16px;
        }
        .question-answer{
            cursor: pointer;
            user-select: none;
            width: 100%;
            min-height: 48px;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            margin-bottom: 12px;
            padding: 10px;
            word-break: break-word;
            &:last-child{
                margin-bottom: 0;
            }
            &.selected{
                background: #5187EF;
                border-color: #5187EF;
                color: #FFFFFF;
            }
        }
    }
    .question-opeationBox{
        justify-content: space-between;
        margin-top: 40px;
        .w220{
            width: 220px;
        }
    }
    .noMessage-box{
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 24px;
        padding: 150px 0;
    }
}
.mb-main{
    .SurveyRespond-main{
        padding: 0 .24rem .9rem .24rem;
        position: relative;
        width: 100%;
        z-index: 2;
        &::after{
            content: '';
            position: fixed;
            width: 114px;
            height: 130px;
            top: 0;
            right: 0;
            background: url('../../../../assets/img/nav-top-bg.png') no-repeat;
            z-index: 1;
            background-size: cover;
        }
        .logo-box{
            display: none;
        }
        .profile-rightBox{
            position: relative;
            z-index: 2;
            padding-top: 8px;
            width: 100%;
        }
        .profile-numBox{
            .statistics-card{
                width: 100%;
                padding: 20px;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: rgba(255, 255, 255, 0.7);
                line-height: .2rem;
                text-align: left;
                background: #5187EF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #5187EF;
                letter-spacing: -0.2px;
                position: relative;
                text-align: center;  
                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    background: url('../../../../assets/img/gift.png') no-repeat;
                    background-size: cover;
                    border-radius: 0 8px 9px 0;
                }
                .sta-num{
                    font-family: Inter-Bold, Inter;
                    font-weight: bold;
                    font-size: .24rem;
                    color: #FFFFFF;
                    line-height: .32rem;
                    margin-top: .1rem;
                }
                .mar10{
                    margin: 10px 0;
                }
            }
        }
        .question-num{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: .16rem;
            color: #FFFFFF;
            line-height: .20rem;
            margin: .24rem 0 .16rem 0;
        }
        .cate_tip{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: .12rem;
            color: #FFFFFF;
            line-height: .16rem;
        }
        .category-boxM{
            margin: .08rem 0 .24rem 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-height: 48px;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            width: 100%;
            padding: .12rem;
            font-weight: 400;
            font-size: .12rem;
            color: #FFFFFF;
            line-height: .16rem;
            user-select: none;
            word-break: break-word;
            .category-icon{
                width: .24rem;
                min-width: .24rem;
                height: .24rem;
                margin-right: 4px;
            }
        }
        .marb8{
            margin-bottom: .08rem;
        }
        .question-box{
            width: 100%;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            padding: .16rem;
            .question-tit{
                font-family: Inter-SemiBold, Inter;
                font-weight: normal;
                font-size: .16rem;
                color: #FFFFFF;
                line-height: .24rem;
                text-align: left;
                margin-bottom: .16rem;
            }
            .question-answer{
                cursor: pointer;
                user-select: none;
                width: 100%;
                min-height: 48px;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: .14rem;
                color: #FFFFFF;
                margin-bottom: .12rem;
                padding: 10px;
                word-break: break-word;
                &:last-child{
                    margin-bottom: 0;
                }
                &.selected{
                    background: #5187EF;
                    border-color: #5187EF;
                    color: #FFFFFF;
                }
            }
        }
        .question-opeationBox{
            position: fixed;
            bottom: 0px;
            justify-content: space-between;
            margin-top: 0px;
            left: 0;
            width: 100%;
            padding: .1rem .24rem .1rem .24rem;
            background-color: #000000;
            .w220{
                width: 163px;
                height: 40px;
                font-size: 14px;
                &:first-child{
                    margin-right: .16rem;
                }
            }
            .r-gray_btn.disabled{
                background-color: #191919;
                opacity: 1;
                color: #8C8C8C;
                border: 1px solid #5E5E5E;
            }
        }
        .noMessage-box{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            padding:2rem 0;
        }
    }
}