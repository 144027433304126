.nav_top{
    width: 100%;
    height: 64px;
    background: #1a1a1a;
    border-radius: 0px 0px 0px 0px;
    padding: 0 10%;
    .logo-box{
        width: 62px;
        height: 48px;
        cursor: pointer;
    }
    .top-tip{
        font-family: Inter-Regular;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 23px;
        font-style: normal;
        margin-left: auto;
        margin-bottom: 0;
    }
    .r-primary_btn{
        width: 160px;
        margin-left: 50px;
    }
    .sub_tip{
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        opacity: .7;
        margin-left: 30px;
    }
    .navUl{
        margin-left: auto;
        .navItem{
            font-family: Inter-SemiBold, Inter;
            font-weight: normal;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 64px;
            margin-right: 30px;
            user-select: none;
            cursor: pointer;
            position: relative;
            transition: all .2s;
            &:last-child{
                margin: 0;
            }
            &::after{
                content: '';
                width: 0%;
                height: 2px;
                background-color: #FF7957;
                position: absolute;
                bottom: 0;
                left: 50%;
                transition: all .2s;
            }
            &.active{
                color: #FF7957;
                &::after{
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
    .noneBox{
        display: none !important;
    }
}
.mb-main{
    .nav_top{
        width: 100%;
        height: 78px;
        background: #191919;
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid rgba(255,255,255,0.1);
        padding: 0 .24rem;
        justify-content: center;
        position: relative;
        box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.15);
        .r-primary_btn,.top-tip,.logo-box{
            display: none;
        }
        .menu-icon{
            position: absolute;
            left: .24rem;
            width: 31px;
            height: 31px;
            background: url('../../assets/img/home/menu.png') no-repeat;
            background-size: contain;
        }
        .logo-icon{
            width: 86px;
            height: 67px;
            // background: url('../../assets/img/logo.png') no-repeat;
            background-size: contain;
        }
        .top_btn{
            width: 89px;
            height: 40px;
            background: rgba(255,255,255,0.05);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #FFFFFF;
            font-family: Inter-Medium;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            margin-left: auto;
        }
    }
    .black_navBox{
        background-color: #000000;
        box-shadow: none;
    }
}
// deawer
.r-drawer{
    width: 3.3rem !important;
    .ant-drawer-body{
        background-color: #333333;
        padding: .24rem;
        .top-close{
            .drawerLogo{
                width: .52rem;
                height: .4rem;
            }
            .drawerClose{
                margin-left: auto;
                width: .24rem;
                height: .24rem;
            }
        }
        .drawer-tip{
            font-family: Inter;
            font-weight: 400;
            font-size: .14rem;
            color: #FFFFFF;
            line-height: .2rem;
            margin: .48rem 0 .3rem 0;
        }
        .r-primary_btn{
            width: 100%;
            height: .4rem;
            font-size: .14rem;
            font-family: Inter-Medium;
        }
        .navDrawer-box{
            height: calc(var(--app-height) - .88rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .sub_tip{
                display: block;
                font-family: Inter, Inter;
                font-weight: 400;
                font-size: .14rem;
                color: #FFFFFF;
                line-height: .2rem;
                margin-bottom:.5rem;
            }
            .navUl{
                margin-top: .08rem;
                .navItem{
                    width: 100%;
                    padding: .17rem 0;
                    font-family: Inter-SemiBold, Inter;
                    font-weight: normal;
                    font-size: .14rem;
                    color: #FFFFFF;
                    line-height: .2rem;
                    user-select: none;
                    cursor: pointer;
                    position: relative;
                    transition: all .2s;
                    margin: 0;
                    &::after{
                        display: none;
                    }
                    &.active{
                        color: #FF7957;
                    }
                }
            }
        }
    }
    .noneBox{
        display: none !important;
    }
}