.subscriptionList-box{
    .subList-box{
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        .sub-card{
            flex: 1;
            // height: 635px;
            height: 610px;
            background: rgba(255,255,255,0.1);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(255,255,255,0.3);
            &:nth-child(2){
                margin: 0 17px;
            }
            .subCard_top{
                padding: 20px 20px 10px 20px;
                position: relative;
                background: rgba(255,255,255,0.05);
                align-items: flex-start;
                .sub-icon{
                    width: 72px;
                    height: 72px;
                    margin-right: 12px;
                }
                .sub-tit{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 29px;
                }
                .sub-price{
                    font-family: Inter-Bold;
                    font-weight: bold;
                    font-size: 24px;
                    color: #5187EF;
                    margin-top: 5px;
                }
                .maxIcBox{
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    margin-left: 6px;
                    opacity: .7;
                }
                .mostBox{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 116px;
                    height: 32px;
                    background: #FF7957;
                    border-radius: 0px 8px 0px 8px;
                    font-size: 12px;
                    font-weight: 500;
                    font-family: Inter-Medium;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 32px;
                }
            }
            .sub_content{
                padding: 20px;
                .sub-listBoxnew{
                    max-height: 384px;
                    overflow-y: auto;
                    margin-bottom: 16px;
                }
                .subInfo_box{
                    margin-bottom: 16px;
                    align-items: flex-start;
                    &:last-child{
                        margin: 0;
                    }
                }
                .check_icon{
                    width: 16px;
                    min-width: 16px;
                    height: 16px;
                    margin:2px 8px 0 0;
                    background: url('../../assets/img/home/checked.png') no-repeat;
                    background-size: contain;
                }
                .sub_tip{
                    font-family: Inter-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    opacity: .7;
                }
                .sub_tipBtm{
                    padding: 8px;
                    width: 100%;
                    background: #70402f;
                    border-radius: 6px 6px 6px 6px;
                    margin: 0;
                    .tip_icon{
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        margin:2px 8px 0 0;
                        background: url('../../assets/img/home/tip.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}

.mb-main{
    .subscriptionList-box{
        .subList-box{
            justify-content: space-between;
            align-items: stretch;
            padding:0 .24rem .5rem .24rem;
            .sub-card{
                flex: 1;
                min-height: auto;
                height: 5.03rem;
                background: rgba(255,255,255,0.1);
                border-radius: 8px 8px 8px 8px;
                border: 1px solid rgba(255,255,255,0.3);
                .subCard_top{
                    padding: .2rem .2rem .1rem .2rem;
                    position: relative;
                    background: rgba(255,255,255,0.05);
                    align-items: flex-start;
                    .sub-icon{
                        width: .72rem;
                        height: .72rem;
                        margin-right: .12rem;
                    }
                    .sub-tit{
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: .2rem;
                        color: #FFFFFF;
                        line-height: .24rem;
                    }
                    .sub-price{
                        height: 48px;
                        font-family: Inter-Bold;
                        font-weight: bold;
                        font-size: .23rem;
                        color: #5187EF;
                        margin-top: 5px;
                    }
                    .maxIcBox{
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                        line-height: 20px;
                        margin-left: 6px;
                        opacity: .7;
                    }
                    .mostBox{
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1.16rem;
                        height: .32rem;
                        background: #FF7957;
                        border-radius: 0px 8px 0px 8px;
                        font-size: .12rem;
                        font-weight: 500;
                        font-family: Inter-Medium;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: .32rem;
                    }
                }
                .sub_content{
                    padding: .2rem;
                    .sub-listBoxnew{
                        max-height: 2.95rem;
                        overflow-y: auto;
                        margin-bottom: .16rem;
                    }
                    .subInfo_box{
                        margin-bottom: .16rem;
                        align-items: flex-start;
                        &:last-child{
                            margin: 0;
                        }
                    }
                    .check_icon{
                        width: .16rem;
                        min-width: .16rem;
                        height: .16rem;
                        margin:.01rem .08rem 0 0;
                        background: url('../../assets/img/home/checked.png') no-repeat;
                        background-size: contain;
                    }
                    .sub_tip{
                        font-family: Inter-Regular;
                        font-weight: 400;
                        font-size: .12rem;
                        color: #FFFFFF;
                        line-height: .16rem;
                        opacity: .7;
                    }
                    .sub_tipBtm{
                        margin: 0;
                        padding: .08rem;
                        width: 100%;
                        background: #70402f;
                        border-radius: 6px 6px 6px 6px;
                        .tip_icon{
                            width: .16rem;
                            min-width: .16rem;
                            height: .16rem;
                            margin:.02rem .08rem 0 0;
                            background: url('../../assets/img/home/tip.png') no-repeat;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
    .introduction-card{
        .swiper-pagination-bullets.swiper-pagination-horizontal{
            bottom: 8px;
        }
    }
}