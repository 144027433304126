@font-face {
    font-family: Inter-Regular;
    src: url(./Inter/Inter-Regular-9.otf);
}
@font-face {
    font-family: Inter;
    src: url(./Inter/Inter-Regular-9.otf);
}
@font-face {
    font-family: Inter-SemiBold;
    src: url(./Inter/Inter-SemiBold-10.otf);
}
@font-face {
    font-family: Inter-Medium;
    src: url(./Inter/Inter-Medium-8.otf);
}
@font-face {
    font-family: Inter-Bold;
    src: url(./Inter/Inter-Bold-4.otf);
}
@font-face {
    font-family: PlusJakartaSans-Bold;
    src: url(./PlusJakartaSans/PlusJakartaSans-Bold.ttf);
}
@font-face {
    font-family: Plus Jakarta Sans;
    src: url(./PlusJakartaSans/PlusJakartaSans-Bold.ttf);
}